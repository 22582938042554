import { RollbackOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, DescriptionsProps, message, Popconfirm, Row } from 'antd';
import { useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import receivableInitializationApi from '../../../../apis/ReceivableInitializationApi';
import { ReceivableFull, ReceivableInitialization } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import styles from './ReceivableInitializationComponent.module.scss';

const ReceivableInitializationComponent = (props: Props) => {
    const { initialization, receivableFull, onDelete } = props;

    /*** HOOKS ***/
    const intl = useIntl();
    const [, setLoading] = useState<'deleting'>();

    /*** EFFECTS ***/

    /*** METHODS ***/

    const wasVoluntary = receivableFull.events.find((e) => e.state === 'VOLUNTARY_REGISTERED');

    const revert = async () => {
        try {
            setLoading('deleting');
            await receivableInitializationApi.delete(initialization.id!);
            message.success(intl.formatMessage({ id: 'status.receivable.reverted' }));
            onDelete();
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const items: DescriptionsProps['items'] = [
        {
            key: 'principal',
            label: <FormattedMessage id="amount.principal" />,
            children: initialization.amount?.principal !== undefined && (
                <FormattedNumber
                    value={initialization.amount.principal}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency' as any}
                    currency="EUR"
                />
            )
        },
        {
            key: 'surcharge',
            label: (
                <>
                    <FormattedMessage id="amount.surcharge" /> ({initialization.penalty * 100}%)
                </>
            ),
            children: initialization.amount?.surcharge !== undefined && (
                <FormattedNumber
                    value={initialization.amount.surcharge}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency' as any}
                    currency="EUR"
                />
            )
        },

        {
            key: 'total',
            label: <FormattedMessage id="amount.total" />,
            children: initialization.amount?.total !== undefined && (
                <strong>
                    <FormattedNumber
                        value={initialization.amount.total}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        style={'currency' as any}
                        currency="EUR"
                    />
                </strong>
            )
        }
    ];

    return (
        <>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <Descriptions layout="horizontal" items={items} size="default" column={4} />
                </Col>
            </Row>
            {wasVoluntary !== undefined && (
                <Row gutter={[28, 0]} className={styles.buttons}>
                    <Col span={24}>
                        <Popconfirm
                            title={intl.formatMessage({ id: 'receivableInitializationComponent.deleteModal.title' })}
                            description={intl.formatMessage({ id: 'receivableInitializationComponent.deleteModal.text' })}
                            onConfirm={revert}
                            okText={intl.formatMessage({ id: 'receivableInitializationComponent.deleteModal.yes' })}
                            cancelText={intl.formatMessage({ id: 'receivableInitializationComponent.deleteModal.no' })}
                        >
                            <Button icon={<RollbackOutlined />}>
                                <FormattedMessage id="button.revert" tagName="span" />
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default ReceivableInitializationComponent;

interface Props {
    initialization: ReceivableInitialization;
    receivableFull: ReceivableFull;
    onDelete: () => void;
}
