import { adminApiAxios } from './CustomAxios';

class ReceivableInitializationApi {
    delete = async (id: number): Promise<void> => {
        const response = await adminApiAxios.delete<void>(`/receivable-initializations/${id}`);

        return response.data;
    };
}

const receivableInitializationApi: ReceivableInitializationApi = new ReceivableInitializationApi();
export default receivableInitializationApi;
