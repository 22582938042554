import { TaxType } from '../models/Types';
import { adminApiAxios } from './CustomAxios';

class ReceivableVoluntaryRegistrationApi {
    download = async (id: number, tax: TaxType): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/receivable-voluntary-registrations/${id}/file`, {
            responseType: 'blob',
            params: { tax }
        });

        return response.data;
    };
}

const receivableVoluntaryRegistrationApi: ReceivableVoluntaryRegistrationApi = new ReceivableVoluntaryRegistrationApi();
export default receivableVoluntaryRegistrationApi;
