import { CloudUploadOutlined, DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, List, Modal, Row, Select, Upload, message } from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { UploadFile } from 'antd/lib';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import councilCollectionAccountApi from '../../../../apis/CouncilCollectionAccountApi';
import paymentApi from '../../../../apis/PaymentApi';
import FileSizeComponent from '../../../../components/FileSizeComponent/FileSizeComponent';
import { CouncilCollectionAccount, PaymentCreation, Receivable } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import styles from './ReceivableVoluntaryPaymentModal.module.scss';

const ReceivableVoluntaryPaymentModal = (props: Props) => {
    const { receivable } = props;
    const maxFileSize = 5 * 1024 * 1024;

    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<'loading' | 'saving'>();
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [councilCollectionAccounts, setCouncilCollectionAccounts] = useState<CouncilCollectionAccount[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                setLoading('loading');
                const councilCollectionAccountsPage = await councilCollectionAccountApi.list(0, 1000, 'iban', true, receivable!.councilId!, 'ENABLED');
                const councilCollectionAccounts = councilCollectionAccountsPage.content;
                setCouncilCollectionAccounts(councilCollectionAccounts);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [form, intl, receivable]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('saving');
            const values = await form.validateFields();
            let paymentCreation: PaymentCreation = {
                councilTaxpayerId: receivable.councilTaxpayer!.id!,
                receivableIds: [receivable.id!],
                councilCollectionAccountId: values.councilCollectionAccountId,
                paid: values.paid
            };
            const attachment: UploadFile | undefined = files.length > 0 ? files[0] : undefined;
            await paymentApi.create(paymentCreation, attachment);
            props.onSave();
            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    // cancel
    const cancel = async () => {
        props.onCancel();
    };

    // validate file
    const validateFile = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void): Promise<void> | void => {
        if (value && value.file && !isFileSizeValid(value.file)) {
            callback(intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };

    const isFileSizeValid = (file: UploadFile) => !file.size || file.size <= maxFileSize;

    // upload file
    const uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!isFileSizeValid(file)) {
            setFiles(files);
        } else {
            files.push(file);
            setFiles(files);
        }

        return false;
    };

    //remove file
    const removeFile = () => {
        const files: UploadFile[] = [];
        form.setFieldsValue({
            file: files
        });
        setFiles(files);
    };

    /*** VISUAL ***/

    const councilCollectionAccountsOptions = councilCollectionAccounts.map((cca) => ({
        value: cca.id,
        label: (
            <span>
                {cca.bank?.name} - {cca.iban}
            </span>
        )
    }));

    return (
        <Modal
            title={<FormattedMessage id="receivable.paid.modal.title" />}
            open={true}
            onCancel={cancel}
            okText={<FormattedMessage id="button.save" tagName="span" />}
            okButtonProps={{ loading: loading === 'saving', size: 'large', htmlType: 'submit' }}
            cancelButtonProps={{ disabled: loading === 'saving', size: 'large' }}
            width={1000}
            destroyOnClose
            modalRender={(dom) => (
                <Form form={form} colon={false} layout="vertical" onFinish={(values) => save(values)}>
                    {dom}
                </Form>
            )}
        >
            <Row gutter={[28, 0]}>
                <Col span={8}>
                    <Form.Item
                        label={<FormattedMessage id="receivable.payment.date" />}
                        name="paid"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <DatePicker size="large" className={styles.date} />
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <Form.Item
                        label={<FormattedMessage id="paymentModal.councilCollectionAccount" />}
                        name="councilCollectionAccountId"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select size="large" options={[...councilCollectionAccountsOptions]} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <Form.Item
                        name="file"
                        valuePropName="files"
                        label={
                            <>
                                <span>
                                    <FormattedMessage id="receivable.payment.modal.upload" />
                                </span>
                            </>
                        }
                        rules={[
                            {
                                validator: validateFile
                            },
                            { required: true, message: <FormattedMessage id="status.mandatory" /> }
                        ]}
                        extra={
                            <>
                                <FormattedMessage id="attachment.size" /> <FileSizeComponent value={maxFileSize} />
                            </>
                        }
                    >
                        <Upload.Dragger beforeUpload={uploadFile} fileList={files} showUploadList={false}>
                            <CloudUploadOutlined /> <FormattedMessage id="button.upload" />
                        </Upload.Dragger>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <List
                        className={styles.files}
                        itemLayout="horizontal"
                        dataSource={files}
                        locale={{ emptyText: <></> }}
                        renderItem={(file) => (
                            <List.Item actions={[<Button icon={<DeleteOutlined />} danger size="large" onClick={removeFile} />]}>
                                <List.Item.Meta
                                    avatar={<PaperClipOutlined className={styles.icon} />}
                                    title={file.name}
                                    description={<FileSizeComponent value={file.size} />}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Modal>
    );
};
export default ReceivableVoluntaryPaymentModal;

interface Props {
    receivable: Receivable;
    onSave: () => void;
    onCancel: () => void;
}
