import { CheckCircleOutlined, CloudUploadOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import censusUrbanPropertyApi from '../../apis/CensusUrbanPropertyApi';
import CensusTransferModal from '../../components/CensusTransferModal/CensusTransferModal';
import ConstantLabel from '../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomContext from '../../contexts/CustomContext';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { BreadcrumbItem, Page } from '../../models/Elements';
import { CensusUrbanProperty, Council } from '../../models/Entities';
import { StatusType } from '../../models/Types';
import alertService from '../../services/AlertService';
import rolesService from '../../services/RolesService';
import tableService from '../../services/TableService';
import styles from './CensusUrbanPropertiesPage.module.scss';

/**
 * Returns the census urban properties page.
 * @returns the census urban properties page.
 */
const CensusUrbanPropertiesPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm<string>();
    const { auth } = useContext(CustomContext);
    const { council } = useContext(CustomCouncilContext);
    const [censusUrbanProperties, setCensusUrbanProperties] = useState<Page<CensusUrbanProperty>>();
    const [censusTransferModal, setCensusTransferModalVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<'loading' | 'saving'>();
    const [page, setPage] = useState<number>(0);
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [filter, setFilter] = useState<Filter>({});

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('loading');
                    const censusUrbanProperties = await censusUrbanPropertyApi.list(
                        page,
                        100,
                        'id',
                        sortOrder,
                        council.id,
                        filter.searchText,
                        filter.transferred,
                        undefined
                    );
                    setCensusUrbanProperties(censusUrbanProperties);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortOrder, filter, council.id, council]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterCensusUrbanProperties = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText,
            transferred: values.transferred
        };
        setFilter(filter);
    };

    const hideModal = () => {
        setCensusTransferModalVisible(false);
    };
    const refresh = async () => {
        setCensusTransferModalVisible(false);
        try {
            if (council && council.id) {
                setLoading('loading');
                const censusUrbanProperties = await censusUrbanPropertyApi.list(
                    page,
                    100,
                    'id',
                    sortOrder,
                    council.id,
                    filter.searchText,
                    filter.transferred,
                    undefined
                );
                setCensusUrbanProperties(censusUrbanProperties);
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);

    const items = censusUrbanProperties ? censusUrbanProperties.content : [];

    const columns: ColumnsType<CensusUrbanProperty> = [
        {
            title: <FormattedMessage id="censusUrbanProperty.fixNumber" />,
            dataIndex: 'fixNumber',
            key: 'fixNumber',
            width: 200,
            fixed: 'left',
            align: 'center',
            sorter: false,
            defaultSortOrder: 'ascend',
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.taxpayer.identifier" />,
            dataIndex: ['taxpayer', 'identifier'],
            key: 'identifier',
            width: 140,
            fixed: 'left',
            align: 'center',
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) =>
                censusUrbanProperty.documentId ? <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link> : value
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.taxpayer.firstName" />,
            dataIndex: ['taxpayer', 'firstName'],
            key: 'firstName',
            width: 220,
            fixed: 'left',
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) =>
                censusUrbanProperty.documentId ? <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link> : value
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.taxpayer.lastName" />,
            dataIndex: ['taxpayer', 'lastName'],
            key: 'lastName',
            width: 220,
            fixed: 'left',
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) =>
                censusUrbanProperty.documentId ? <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link> : value
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.taxpayer.secondLastName" />,
            dataIndex: ['taxpayer', 'secondLastName'],
            key: 'lastName',
            width: 220,
            fixed: 'left',
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) =>
                censusUrbanProperty.documentId ? <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link> : value
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.rights" />,
            dataIndex: 'rights',
            key: 'rights',
            width: 120,
            align: 'center',
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) =>
                censusUrbanProperty.documentId ? <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link> : value
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.rightsPercentage" />,
            dataIndex: 'rightsPercentage',
            key: 'rightsPercentage',
            align: 'center',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) =>
                censusUrbanProperty.documentId ? <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link> : value
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.status" />,
            dataIndex: 'status',
            key: 'status',
            width: 120,
            render: (value: StatusType, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>
                    <ConstantLabel value={value} prefix="status.type." />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.transferred" />,
            dataIndex: 'transferred',
            key: 'transferred',
            width: 120,
            align: 'center',
            render: (value: boolean, censusUrbanProperty: CensusUrbanProperty) =>
                censusUrbanProperty.documentId && (
                    <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value && <CheckCircleOutlined />}</Link>
                )
        },

        {
            title: <FormattedMessage id="censusUrbanProperty.propertyLocation" />,
            dataIndex: 'propertyLocation',
            key: 'propertyLocation',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.propertyLocation2" />,
            dataIndex: 'propertyLocation2',
            key: 'propertyLocation2',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.propertyLocation3" />,
            dataIndex: 'propertyLocation3',
            key: 'propertyLocation3',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.cadastralReference" />,
            dataIndex: 'cadastralReference',
            key: 'cadastralReference',
            align: 'center',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.constructedArea" />,
            dataIndex: 'constructedArea',
            key: 'constructedArea',
            align: 'center',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.landArea" />,
            dataIndex: 'landArea',
            key: 'landArea',
            align: 'center',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.constructionValue" />,
            dataIndex: 'constructionValue',
            key: 'constructionValue',
            align: 'center',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.landValue" />,
            dataIndex: 'landValue',
            key: 'landValue',
            align: 'center',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.cadastralValue" />,
            dataIndex: 'cadastralValue',
            key: 'cadastralValue',
            align: 'center',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.taxableBase" />,
            dataIndex: 'taxableBase',
            key: 'taxableBase',
            align: 'center',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.classification" />,
            dataIndex: 'classification',
            key: 'classification',
            align: 'center',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.taxRate" />,
            dataIndex: 'taxRate',
            key: 'taxRate',
            align: 'center',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.participationRate" />,
            dataIndex: 'participationRate',
            key: 'participationRate',
            align: 'center',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.bonusRate" />,
            dataIndex: 'bonusRate',
            key: 'bonusRate',
            align: 'center',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.bonifiedAmount" />,
            dataIndex: 'bonifiedAmount',
            key: 'bonifiedAmount',
            align: 'center',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.fee" />,
            dataIndex: 'fee',
            key: 'fee',
            align: 'center',
            width: 120,
            render: (value: number, censusUrbanProperty: CensusUrbanProperty) => (
                <Link to={`/councils/${council?.id}/census-urban-properties/${censusUrbanProperty.id}`}>{value}</Link>
            )
        }
    ];

    return (
        <LayoutComponent title={<FormattedMessage id="receivables.title" />} menu={'councils'} path={PathComponent(council)} council={council}>
            <Form form={form} onFinish={filterCensusUrbanProperties} colon={false} layout="vertical" requiredMark={false} className={styles.form}>
                <Row>
                    <Col span={16}>
                        <Space className={styles.filter}>
                            <Form.Item name="searchText">
                                <Search
                                    placeholder={intl.formatMessage({
                                        id: 'censusUrbanProperties.search'
                                    })}
                                    size="large"
                                    allowClear
                                    className={styles.search}
                                    onSearch={form.submit}
                                />
                            </Form.Item>
                            <Form.Item name="transferred" valuePropName="checked">
                                <Checkbox onChange={form.submit} className={styles.checkbox}>
                                    <FormattedMessage id="censusUrbanProperties.transferred" />
                                </Checkbox>
                            </Form.Item>
                        </Space>
                    </Col>
                    <Col span={8} className={styles.buttons}>
                        <Space>
                            {isAdmin && (
                                <Link to={`/councils/${council?.id}/census-urban-properties/import`}>
                                    <Tooltip title={<FormattedMessage id="censusUrbanProperties.import.tooltip" />}>
                                        <Button type="primary" danger size="large" icon={<CloudUploadOutlined />}>
                                            {desktop && <FormattedMessage id="button.import" tagName="span" />}
                                        </Button>
                                    </Tooltip>
                                </Link>
                            )}
                            {isAdmin && (
                                <Tooltip title={<FormattedMessage id="censusUrbanProperties.transfer.tooltip" />}>
                                    <Button type="primary" size="large" icon={<RightCircleOutlined />} onClick={() => setCensusTransferModalVisible(true)}>
                                        {desktop && <FormattedMessage id="button.transfer" tagName="span" />}
                                    </Button>
                                </Tooltip>
                            )}
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                expandable={{ childrenColumnName: 'taxpayers', defaultExpandAllRows: true }}
                pagination={tableService.createPagination(censusUrbanProperties)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className={`table ${styles.table}`}
                scroll={{ x: 400 }}
            />
            {censusTransferModal && (
                <CensusTransferModal councilId={council.id!} tax="IMPUESTO_BIENES_INMUEBLES_DE_NATURALEZA_URBANA" onSave={refresh} onCancel={hideModal} />
            )}
        </LayoutComponent>
    );
};
export default CensusUrbanPropertiesPage;

interface Filter {
    searchText?: string | undefined;
    transferred?: boolean;
}

/**
 * Returns the path of the census urban properties page.
 * @param council the council
 * @returns  the path
 */
const PathComponent = (council: Council): BreadcrumbItem[] => {
    return [
        { path: '/councils', name: <FormattedMessage id="councils.title" /> },
        { path: `/councils/${council.id}`, name: council.name },
        { path: `/councils/${council.id}/census-urban-properties`, name: <FormattedMessage id="censusUrbanProperties.title" /> }
    ];
};
