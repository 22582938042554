import { CloudDownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, List, Modal, Row, Space, message } from 'antd';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import paymentOrderApi from '../../../apis/PaymentOrderApi';
import { PaymentOrder, PaymentOrderCreation, PaymentOrderDraft, ReceivableNotificationBuild } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import styles from './PaymentOrderModal.module.scss';
import { CheckboxProps } from 'antd/lib';

const PaymentOrderModal: React.FC<Props> = (props) => {
    const { councilId, councilTaxpayerId, clone } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading' | 'sending'>();
    const [paymentOrderDraft, setPaymentOrderDraft] = useState<PaymentOrderDraft>();
    const [, setPaymentOrderCreation] = useState<PaymentOrderCreation>();
    const [councilTaxpayerIds, setCouncilTaxpayerIds] = useState<number[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                let councilTaxpayerIds = councilTaxpayerId ? [councilTaxpayerId] : [];
                const paymentOrderCreation: PaymentOrderCreation = {
                    councilId,
                    councilTaxpayerIds,
                    clone
                };
                setPaymentOrderCreation(paymentOrderCreation);
                //create draft
                const paymentOrderDraft = await paymentOrderApi.createDraft(paymentOrderCreation);
                setPaymentOrderDraft(paymentOrderDraft);

                //select all council taxpayer ids
                councilTaxpayerIds = paymentOrderDraft.paymentOrders.map((po) => po.councilTaxpayer.id);
                setCouncilTaxpayerIds(councilTaxpayerIds);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [clone, councilId, councilTaxpayerId, intl]);

    /*** METHODS ***/

    const send = async () => {
        try {
            setLoading('sending');
            const updatedPaymentOrderCreation: PaymentOrderCreation = {
                councilId,
                councilTaxpayerIds,
                clone
            };

            await paymentOrderApi.create(updatedPaymentOrderCreation);
            await props.onSave();
            message.success(intl.formatMessage({ id: 'status.sent' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const cancel = async () => {
        props.onCancel();
    };

    const filterReceivableNotificationBuilds = (paymentOrder: PaymentOrder) => {
        return paymentOrderDraft!.receivableNotificationBuilds.filter(
            (rnb) => rnb.paymentOrder.councilTaxpayer.taxpayer.id === paymentOrder.councilTaxpayer.taxpayer.id
        );
    };

    const updateCouncilTaxpayerIds = (councilTaxpayerId: number) => {
        if (councilTaxpayerIds.includes(councilTaxpayerId)) {
            setCouncilTaxpayerIds(councilTaxpayerIds.filter((e) => e !== councilTaxpayerId));
        } else {
            setCouncilTaxpayerIds([...councilTaxpayerIds, councilTaxpayerId]);
        }
    };

    /*** VISUAL ***/

    const paymentOrders = paymentOrderDraft ? paymentOrderDraft.paymentOrders : [];

    return (
        <Modal
            title={<FormattedMessage id="paymentOrderModal.title" />}
            open={true}
            onCancel={cancel}
            onOk={() => send()}
            okText={<FormattedMessage id="button.send" tagName="span" />}
            okButtonProps={{
                loading: loading === 'sending',
                size: 'large',
                disabled: !paymentOrderDraft || paymentOrderDraft.paymentOrders.length === 0
            }}
            cancelButtonProps={{ size: 'large' }}
            width={1000}
        >
            <Row gutter={[0, 0]} className={styles.container}>
                {!clone && (
                    <Col span={24}>
                        <FormattedMessage id="paymentOrderModal.description" />
                    </Col>
                )}
                {clone && (
                    <Col span={24}>
                        <FormattedMessage id="paymentOrderModal.description.clone" />
                        <p className={styles.alert}>
                            <InfoCircleOutlined /> <FormattedMessage id="paymentOrderModal.description.clone.alert" />
                        </p>
                    </Col>
                )}
                <Col span={24}>
                    <List
                        className={styles.list}
                        size="small"
                        itemLayout="horizontal"
                        dataSource={paymentOrders}
                        loading={loading === 'loading'}
                        renderItem={(paymentOrder) => (
                            <PaymentOrderComponent
                                key={paymentOrder.councilTaxpayer.id}
                                paymentOrder={paymentOrder}
                                onSelected={updateCouncilTaxpayerIds}
                                receivableNotificationBuilds={filterReceivableNotificationBuilds(paymentOrder)}
                                clone={clone}
                            />
                        )}
                    />
                </Col>
            </Row>
        </Modal>
    );
};
export default PaymentOrderModal;

interface Props {
    councilId: number;
    councilTaxpayerId?: number;
    clone: boolean;
    onSave: () => Promise<void>;
    onCancel: () => void;
}

const PaymentOrderComponent: React.FC<PaymentOrderComponentProps> = (props) => {
    const { paymentOrder, receivableNotificationBuilds, clone } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading' | 'downloading'>();

    // useEffect(() => {
    //     const init = async () => {
    //         try {
    //             const principalTotal = receivableNotificationBuilds.map((rnb) => rnb.amount!.principal).reduce((partialSum, a) => partialSum + a, 0);
    //             const checked = principalTotal > 200;
    //             setChecked(checked);
    //         } catch (error) {
    //             alertService.displayError(error, intl);
    //         } finally {
    //             setLoading(undefined);
    //         }
    //     };
    //     init();
    // }, [intl, receivableNotificationBuilds]);

    /*** METHODS ***/

    const download = async (paymentOrder: PaymentOrder) => {
        try {
            setLoading('downloading');
            const document = await paymentOrderApi.downloadDraft(paymentOrder.councilTaxpayer.id, clone);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'paymentOrder.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const selectCouncilTaxpayerId: CheckboxProps['onChange'] = (e) => {
        props.onSelected(e.target.value);
    };

    /*** VISUAL ***/

    const principal = receivableNotificationBuilds.map((rnb) => rnb.amount!.principal).reduce((partialSum, a) => partialSum + a, 0);
    const totalPrincipal: string = intl.formatNumber(principal, {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'EUR'
    });
    const totalSurcharge: string = intl.formatNumber(
        receivableNotificationBuilds.map((rnb) => rnb.amount!.surcharge).reduce((partialSum, a) => partialSum + a, 0),
        {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'EUR'
        }
    );
    const totalCosts: string = intl.formatNumber(paymentOrder.costs, {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'EUR'
    });

    const totalTotal: string = intl.formatNumber(
        receivableNotificationBuilds.map((rnb) => rnb.amount!.total).reduce((partialSum, a) => partialSum + a, paymentOrder.costs),
        {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'EUR'
        }
    );

    return (
        <>
            <List.Item
                actions={[
                    <Button icon={<CloudDownloadOutlined />} danger size="large" onClick={() => download(paymentOrder)} loading={loading === `downloading`} />
                ]}
            >
                <List.Item.Meta
                    title={
                        <span>
                            <Checkbox value={paymentOrder.councilTaxpayer.id} onChange={selectCouncilTaxpayerId} defaultChecked={principal > 10}>
                                {paymentOrder.councilTaxpayer.taxpayer.fullName}
                            </Checkbox>
                        </span>
                    }
                    description={
                        <Space split="|">
                            <FormattedMessage id="paymentOrderModal.receivables" values={{ receivables: receivableNotificationBuilds.length }} />
                            <FormattedMessage
                                id="paymentOrderModal.total.principal"
                                values={{
                                    totalPrincipal: totalPrincipal
                                }}
                            />
                            <FormattedMessage
                                id="paymentOrderModal.total.surcharge"
                                values={{
                                    totalSurcharge: totalSurcharge
                                }}
                            />
                            <FormattedMessage
                                id="paymentOrderModal.total.costs"
                                values={{
                                    totalCosts: totalCosts
                                }}
                            />
                            <strong>
                                <FormattedMessage
                                    id="paymentOrderModal.total.total"
                                    values={{
                                        total: totalTotal
                                    }}
                                />
                            </strong>
                        </Space>
                    }
                />
            </List.Item>
        </>
    );
};

interface PaymentOrderComponentProps {
    paymentOrder: PaymentOrder;
    onSelected: (councilTaxpayerId: number) => void;
    receivableNotificationBuilds: ReceivableNotificationBuild[];
    clone: boolean;
}
