import { Page } from '../models/Elements';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';
import { Taxpayer, TaxpayerVersion, TaxpayerWithVersion } from '../models/Entities';

class TaxpayerApi {
    list = async (page: number, size: number, sortField: string, sortOrder: boolean, searchText?: string): Promise<Page<Taxpayer>> => {
        const response = await adminApiAxios.get<Page<Taxpayer>>(`/taxpayers`, {
            params: { page, size, sortField, sortOrder, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((taxpayer) => this.loadDates(taxpayer));

        return response.data;
    };

    get = async (id: number): Promise<Taxpayer> => {
        const response = await adminApiAxios.get<Taxpayer>(`/taxpayers/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (taxpayerWithVersion: TaxpayerWithVersion, attachment: any): Promise<Taxpayer> => {
        const formData: FormData = this.buildFormData(taxpayerWithVersion, attachment);
        const response = await adminApiAxios.post<Taxpayer>(`/taxpayers`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    update = async (taxpayerWithVersion: TaxpayerWithVersion, attachment: any): Promise<Taxpayer> => {
        const formData: FormData = this.buildFormData(taxpayerWithVersion, attachment);
        const response = await adminApiAxios.put<Taxpayer>(`/taxpayers/${taxpayerWithVersion.taxpayer.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (id: number, taxpayerVersion: TaxpayerVersion, attachment: any): Promise<void> => {
        const formData: FormData = this.buildTaxpayerVersionFormData(taxpayerVersion, attachment);
        const response = await adminApiAxios.delete<void>(`/taxpayers/${id}`, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        });

        return response.data;
    };

    /**
     * Loads the dates of a taxpayers.
     * @param taxpayer - the taxpayer
     */
    private loadDates(taxpayer: Taxpayer) {
        taxpayer.notificationDate = dateService.transformDate(taxpayer.notificationDate)!;
        taxpayer.audit!.created = dateService.transformDate(taxpayer.audit!.created)!;
        taxpayer.audit!.updated = dateService.transformDate(taxpayer.audit!.updated)!;
    }

    /**
     * Builds a form data.
     * @param taxpayerWithVersion the taxpayer with version
     * @param attachment the attachment
     * @returns the form data
     */
    private buildFormData = (taxpayerWithVersion: TaxpayerWithVersion, attachment?: File) => {
        const formData: FormData = new FormData();
        taxpayerWithVersion.taxpayer.id && formData.append('taxpayer.id', taxpayerWithVersion.taxpayer.id.toString());
        taxpayerWithVersion.taxpayer.identifier && formData.append('taxpayer.identifier', taxpayerWithVersion.taxpayer.identifier);
        taxpayerWithVersion.taxpayer.firstName && formData.append('taxpayer.firstName', taxpayerWithVersion.taxpayer.firstName);
        taxpayerWithVersion.taxpayer.lastName && formData.append('taxpayer.lastName', taxpayerWithVersion.taxpayer.lastName);
        taxpayerWithVersion.taxpayer.secondLastName && formData.append('taxpayer.secondLastName', taxpayerWithVersion.taxpayer.secondLastName);
        taxpayerWithVersion.taxpayer.legalEntity && formData.append('taxpayer.legalEntity', taxpayerWithVersion.taxpayer.legalEntity);
        taxpayerWithVersion.taxpayer.status && formData.append('taxpayer.status', taxpayerWithVersion.taxpayer.status);
        taxpayerWithVersion.taxpayer.address?.street && formData.append('taxpayer.address.street', taxpayerWithVersion.taxpayer.address.street);
        taxpayerWithVersion.taxpayer.address?.city && formData.append('taxpayer.address.city', taxpayerWithVersion.taxpayer.address.city);
        taxpayerWithVersion.taxpayer.address?.province && formData.append('taxpayer.address.province', taxpayerWithVersion.taxpayer.address.province);
        taxpayerWithVersion.taxpayer.address?.state && formData.append('taxpayer.address.state', taxpayerWithVersion.taxpayer.address.state);
        taxpayerWithVersion.taxpayer.address?.postcode &&
            formData.append('taxpayer.address.postcode', taxpayerWithVersion.taxpayer.address.postcode.toString());
        taxpayerWithVersion.taxpayer.address?.phone && formData.append('taxpayer.address.phone', taxpayerWithVersion.taxpayer.address.phone);
        taxpayerWithVersion.taxpayer.address?.email && formData.append('taxpayer.address.email', taxpayerWithVersion.taxpayer.address.email);
        taxpayerWithVersion.taxpayer.notification && formData.append('taxpayer.notification', taxpayerWithVersion.taxpayer.notification);
        taxpayerWithVersion.taxpayer.notificationDate &&
            formData.append('taxpayer.notificationDate', taxpayerWithVersion.taxpayer.notificationDate.format('YYYY-MM-DDThh:mm:ss'));
        taxpayerWithVersion.version.remarks && formData.append('version.remarks', taxpayerWithVersion.version.remarks);
        attachment && formData.append('version.attachment.name', attachment.name);
        attachment && formData.append('version.attachment.file', attachment);

        return formData;
    };

    /**
     * Builds a form data from a taxpayer version.
     * @param taxpayerVersion the taxpayer version
     * @param attachment the attachment
     * @returns the form data
     */
    private buildTaxpayerVersionFormData = (taxpayerVersion: TaxpayerVersion, attachment?: File) => {
        const formData: FormData = new FormData();
        taxpayerVersion.remarks && formData.append('remarks', taxpayerVersion.remarks);
        attachment && formData.append('attachment.name', attachment.name);
        attachment && formData.append('attachment.file', attachment);

        return formData;
    };
}

const taxpayerApi: TaxpayerApi = new TaxpayerApi();
export default taxpayerApi;
