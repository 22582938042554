import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import statisticsApi from '../../../apis/StatisticsApi';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import { StatsReceivableState, StatsReceivableStates } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import styles from './StatisticsReceivablesByStateComponent.module.scss';

/**
 *
 * @param props the props
 * @returns the statistics receivable by state component
 */
const StatisticsReceivablesByStateComponent: React.FC<Props> = (props) => {
    const { councilId, startDate, endDate } = props;

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading'>();
    const [receivablesByState, setReceivablesByState] = useState<StatsReceivableStates>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const receivablesByState = await statisticsApi.getReceivableStates(councilId, startDate, endDate);
                setReceivablesByState(receivablesByState);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, endDate, intl, startDate]);

    /*** HOOKS ***/

    /*** METHODS ***/
    /*** VISUAL ***/

    const columns: ColumnsType<StatsReceivableState> = [
        {
            title: <FormattedMessage id="statsReceivableState.state" />,
            dataIndex: 'state',
            key: 'state',
            render: (value: string, statsReceivableState: StatsReceivableState) => <ConstantLabel value={value} prefix="receivable.state." />
        },
        {
            title: <FormattedMessage id="statsReceivableState.amount.principal" />,
            dataIndex: ['amount', 'principal'],
            key: 'amountPrincipal',
            align: 'right',
            width: 350,
            render: (value: number, statsReceivableState: StatsReceivableState) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },

        {
            title: <FormattedMessage id="statsReceivableState.count" />,
            dataIndex: 'count',
            key: 'count',
            align: 'right',
            width: 350
        }
    ];

    const data: StatsReceivableState[] = receivablesByState && receivablesByState.statsReceivableStates ? receivablesByState?.statsReceivableStates : [];
    return (
        <div className={styles.container}>
            <Table
                className={styles.table}
                dataSource={data}
                columns={columns}
                pagination={false}
                rowKey="id"
                showSorterTooltip={false}
                loading={loading === 'loading'}
                summary={(pageData) => {
                    return (
                        <>
                            <Table.Summary.Row className={styles.summary}>
                                <Table.Summary.Cell index={0}>
                                    <FormattedMessage id="statsReceivableState.total" />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} align="right">
                                    {receivablesByState && receivablesByState.amount && (
                                        <FormattedNumber
                                            value={receivablesByState.amount.principal}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2} align="right">
                                    {receivablesByState && receivablesByState.count && <FormattedNumber value={receivablesByState!.count} />}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </div>
    );
};
export default StatisticsReceivablesByStateComponent;

interface Props {
    councilId: number;
    startDate: Dayjs;
    endDate: Dayjs;
}
