import { ReceivableVoluntarySent, ReceivableVoluntarySentBulk } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class ReceivableVoluntarySentApi {
    send = async (receivableVoluntarySent: ReceivableVoluntarySent, file: any): Promise<ReceivableVoluntarySent> => {
        const formData: FormData = this.buildFormData(receivableVoluntarySent, file);
        const response = await adminApiAxios.post<ReceivableVoluntarySent>(`/receivable-voluntary-sents`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    sendBulk = async (receivableVoluntarySentBulk: ReceivableVoluntarySentBulk, file: any): Promise<ReceivableVoluntarySentBulk> => {
        const formData: FormData = this.buildFormDataBulk(receivableVoluntarySentBulk, file);
        const response = await adminApiAxios.post<ReceivableVoluntarySentBulk>(`/receivable-voluntary-sents/bulk`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadBulkDates(response.data);

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/receivable-voluntary-sents/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Builds a form data from a receivable voluntary sent.
     * @param receivableVoluntarySent the receivable voluntary sent
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (receivableVoluntarySent: ReceivableVoluntarySent, file?: File) => {
        const formData: FormData = new FormData();
        receivableVoluntarySent.id && formData.append('id', receivableVoluntarySent.id.toString());
        receivableVoluntarySent.receivableId && formData.append('receivableId', receivableVoluntarySent.receivableId.toString());
        receivableVoluntarySent.state && formData.append('state', receivableVoluntarySent.state.toString());
        receivableVoluntarySent.sent && formData.append('sent', receivableVoluntarySent.sent.format('YYYY-MM-DDThh:mm:ss'));
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Builds a form data from a receivable voluntary sent bulk.
     * @param receivableVoluntarySentBulk the receivable voluntary sent bulk
     * @param file the file
     * @returns the form data
     */
    private buildFormDataBulk = (receivableVoluntarySentBulk: ReceivableVoluntarySentBulk, file?: File) => {
        const formData: FormData = new FormData();
        receivableVoluntarySentBulk.councilId && formData.append('councilId', receivableVoluntarySentBulk.councilId.toString());
        receivableVoluntarySentBulk.tax && formData.append('tax', receivableVoluntarySentBulk.tax);
        receivableVoluntarySentBulk.receivableIds && formData.append('receivableIds', receivableVoluntarySentBulk.receivableIds.toString());
        receivableVoluntarySentBulk.sent && formData.append('sent', receivableVoluntarySentBulk.sent.format('YYYY-MM-DDThh:mm:ss'));
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Loads the dates of a receivable voluntary sent.
     * @param receivableVoluntarySent - the receivable voluntary sent
     */
    private loadDates(receivableVoluntarySent: ReceivableVoluntarySent) {
        receivableVoluntarySent.audit!.created = dateService.transformDate(receivableVoluntarySent.audit!.created)!;
        receivableVoluntarySent.audit!.updated = dateService.transformDate(receivableVoluntarySent.audit!.updated)!;
    }

    /**
     * Loads the dates of a receivable voluntary sent bulk.
     * @param receivableVoluntarySentBulk receivable voluntary sent bulk
     */
    private loadBulkDates(receivableVoluntarySentBulk: ReceivableVoluntarySentBulk) {
        receivableVoluntarySentBulk.sent = dateService.transformDate(receivableVoluntarySentBulk.sent)!;
    }
}

const receivableVoluntarySentApi: ReceivableVoluntarySentApi = new ReceivableVoluntarySentApi();
export default receivableVoluntarySentApi;
