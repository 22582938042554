import { Dayjs } from 'dayjs';
import {
    StatsReceivableRegistrationIdentifierAndTaxAndFiscalYears,
    StatsReceivableStates,
    StatsReceivableTaxByTaxAndFiscalYearAndCreatedYears
} from '../models/Entities';

import { adminApiAxios } from './CustomAxios';

class StatisticsApi {
    getReceivableTaxFiscalYearCreatedYears = async (
        councilId: number,
        startDate: Dayjs,
        endDate: Dayjs
    ): Promise<StatsReceivableTaxByTaxAndFiscalYearAndCreatedYears> => {
        const response = await adminApiAxios.get<StatsReceivableTaxByTaxAndFiscalYearAndCreatedYears>('/stats/receivable-tax-fiscal-year-created-years', {
            params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON() }
        });

        return response.data;
    };

    getReceivableStates = async (councilId: number, startDate: Dayjs, endDate: Dayjs): Promise<StatsReceivableStates> => {
        const response = await adminApiAxios.get<StatsReceivableStates>('/stats/receivable-states', {
            params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON() }
        });

        return response.data;
    };

    getReceivableRegistrationIdentifierTaxFiscalYears = async (
        councilId: number,
        startDate: Dayjs,
        endDate: Dayjs
    ): Promise<StatsReceivableRegistrationIdentifierAndTaxAndFiscalYears> => {
        const response = await adminApiAxios.get<StatsReceivableRegistrationIdentifierAndTaxAndFiscalYears>(
            '/stats/receivable-registration-identifier-tax-fiscal-years',
            {
                params: { councilId, startDate: startDate.toJSON(), endDate: endDate.toJSON() }
            }
        );

        return response.data;
    };
}

const statisticsApi: StatisticsApi = new StatisticsApi();
export default statisticsApi;
