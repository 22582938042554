import { Col, DatePicker, Form, Modal, Row, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import censusUrbanPropertyApi from '../../apis/CensusUrbanPropertyApi';
import { CensusTransfer } from '../../models/Entities';
import { periodTypes, subtaxTypes, TaxType } from '../../models/Types';
import alertService from '../../services/AlertService';
import ConstantLabel from '../ConstantLabel/ConstantLabel';
import styles from './CensusTransferModal.module.scss';

/**
 * Returns the modal to transfer census entities.
 * @returns the modal to transfer census entities
 */
const CensusTransferModal: React.FC<Props> = (props) => {
    const { councilId, tax, onSave, onCancel } = props;

    /*** HOOKS ***/

    const [form] = Form.useForm();
    const intl = useIntl();
    const [loading, setLoading] = useState<'loading' | 'refreshing' | 'deleting'>();
    const [, setStartDate] = useState<Dayjs>();
    const [, setEndDate] = useState<Dayjs>();
    const range = dayjs().year() - 2018;

    useEffect(() => {
        const init = async () => {
            try {
                if (councilId) {
                    const startDate = dayjs().subtract(3, 'month');
                    const endDate = dayjs();
                    setStartDate(startDate);
                    setEndDate(endDate);
                    form.setFieldsValue({ startDate, endDate });
                }
            } catch (error) {
                alertService.displayError(error, intl);
            }
        };
        init();
    }, [intl, form, councilId]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');

            const censusTransfer: CensusTransfer = Object.assign({}, { councilId, tax }, values);
            if (censusTransfer.tax === 'IMPUESTO_BIENES_INMUEBLES_DE_NATURALEZA_URBANA') {
                await censusUrbanPropertyApi.transfer(censusTransfer);
            }

            onSave();
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const cancel = () => {
        onCancel();
    };

    /*** VISUAL ***/

    const subtaxOptions = subtaxTypes.map((s) => ({ value: s, label: <ConstantLabel value={s} prefix="receivable.subtax." /> }));

    const fiscalPeriodOptions = periodTypes.map((pt) => ({ value: pt, label: <ConstantLabel value={pt} prefix="period.type." /> }));

    const fiscalYearOptions = Array.from({ length: range }, (x, i) => i + 2020)
        .reverse()
        .map((year) => ({ value: year, label: year }));

    return (
        <Modal
            title={<FormattedMessage id="censusTransferModal.title" />}
            open={true}
            onCancel={cancel}
            okText={<FormattedMessage id="button.save" tagName="span" />}
            okButtonProps={{
                loading: loading === 'loading',
                size: 'large',
                htmlType: 'submit'
            }}
            cancelButtonProps={{ size: 'large' }}
            width={1100}
            destroyOnClose
            modalRender={(dom) => (
                <Form form={form} colon={false} layout="vertical" onFinish={(values) => save(values)}>
                    {dom}
                </Form>
            )}
        >
            <Row gutter={[28, 0]} className={styles.title}>
                <Col xs={24} md={6}>
                    <Form.Item
                        label={<FormattedMessage id="censusTransferModal.paymentStarted" />}
                        name="paymentStarted"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <DatePicker className={styles.date} />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item
                        label={<FormattedMessage id="censusTransferModal.paymentEnded" />}
                        name="paymentEnded"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <DatePicker className={styles.date} />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        label={<FormattedMessage id="censusTransferModal.subtax" />}
                        name="subtax"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select options={[...subtaxOptions]} />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item
                        label={<FormattedMessage id="censusTransferModal.fiscalYear" />}
                        name="fiscalYear"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select options={[...fiscalYearOptions]} />
                    </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                    <Form.Item
                        label={<FormattedMessage id="censusTransferModal.fiscalPeriod" />}
                        name="fiscalPeriod"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select options={[...fiscalPeriodOptions]} />
                    </Form.Item>
                </Col>
            </Row>
        </Modal>
    );
};
export default CensusTransferModal;

interface Props {
    councilId: number;
    tax: TaxType;
    onSave: () => void;
    onCancel: () => void;
}
