import { ArrowLeftOutlined, CloudDownloadOutlined, FilePdfOutlined, DownloadOutlined, FormOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Table, Tag, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { Dayjs } from 'dayjs';
import FileSaver from 'file-saver';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import announcementApi from '../../apis/AnnouncementApi';
import AnnouncementPublicationModal from '../../components/AnnouncementPublicationModal/AnnouncementPublicationModal';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { Page } from '../../models/Elements';
import { Announcement, CustomAuth } from '../../models/Entities';
import alertService from '../../services/AlertService';
import tableService from '../../services/TableService';
import styles from './AnnouncementsPage.module.scss';
import CustomContext from '../../contexts/CustomContext';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import AnnouncementModal from '../../components/ReceivableActionsMenu/AnnouncementModal/AnnouncementModal';

/**
 * Returns the announcements page.
 * @returns the announcements page.
 */

const AnnouncementsPage: React.FC = () => {
    /***HOOKS */

    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const [form] = Form.useForm();
    const { council } = useContext(CustomCouncilContext);
    const [filter, setFilter] = useState<Filter>({});
    const [announcementsPage, setAnnouncementsPage] = useState<Page<Announcement>>();
    const [announcementPublicationModal, setAnnouncementUpdateModalVisible] = useState<boolean>(false);
    const [announcement, setAnnouncement] = useState<Announcement>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('auditCreated');
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [loading, setLoading] = useState<'loading' | 'downloading'>();
    const [announcementModalVisible, setAnnouncementModalVisible] = useState<boolean>(false);

    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('loading');
                    const announcementsPage = await announcementApi.list(page, 100, sortField, sortOrder, council.id, filter.searchText);
                    setAnnouncementsPage(announcementsPage);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter, council]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterPaymentOrderSigns = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    const downloadSigned = async (announcement: Announcement) => {
        try {
            setLoading('downloading');
            const document = await announcementApi.download(announcement.id, 'LATEST');
            FileSaver.saveAs(document, intl.formatMessage({ id: 'announcement.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const downloadXml = async (announcement: Announcement) => {
        try {
            setLoading('downloading');
            const document = await announcementApi.downloadXml(announcement.id);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'announcement.xml.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const downloadBulletin = async (announcement: Announcement) => {
        try {
            if (announcement.bulletinFile) {
                setLoading('downloading');
                const document = await announcementApi.downloadBulletin(announcement);
                FileSaver.saveAs(document, announcement.bulletinFile.name);
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const showAnnouncementUpdateModal = async (announcement: Announcement) => {
        setAnnouncement(announcement);
        setAnnouncementUpdateModalVisible(true);
    };

    const cancel = () => {
        setAnnouncementUpdateModalVisible(false);
    };

    const save = async () => {
        try {
            setLoading('loading');
            setAnnouncementUpdateModalVisible(false);
            setAnnouncementModalVisible(false);
            const announcementsPage = await announcementApi.list(page, 100, sortField, sortOrder, council.id, filter.searchText);
            setAnnouncementsPage(announcementsPage);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const isAdmin = auth.authorities.includes('ROLE_ADMIN');

    const items = announcementsPage ? announcementsPage.content : [];
    const columns: ColumnsType<Announcement> = [
        {
            title: <FormattedMessage id="announcement.id" />,
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 40,
            sorter: true,
            defaultSortOrder: 'descend',
            render: (value: string, announcement: Announcement) => <Link to={`/councils/${council?.id}/announcements/${value}`}>{value}</Link>
        },

        {
            title: <FormattedMessage id="announcement.treasurerSigned.status" />,
            dataIndex: 'treasurerSigned',
            key: 'treasurerSigned',
            align: 'center',
            width: 140,
            render: (value: Dayjs, announcement: Announcement) => (
                <Link to={`/councils/${council?.id}/announcements/${announcement.id}`}>
                    {value ? (
                        <Tooltip title={<FormattedDate value={value as any} dateStyle="medium" timeStyle="short" hour12={false} />}>
                            <Tag color="green">
                                <FormattedMessage id="announcement.signed" />
                            </Tag>
                        </Tooltip>
                    ) : (
                        <Tag color="red">
                            <FormattedMessage id="announcement.notSigned" />
                        </Tag>
                    )}
                </Link>
            )
        },
        {
            title: <FormattedMessage id="announcement.treasurer.name" />,
            dataIndex: ['treasurer', 'fullName'],
            key: 'treasurer.fullName',
            align: 'center',
            render: (value: string, announcement: Announcement) => <Link to={`/councils/${council?.id}/announcements/${announcement.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="announcement.created" />,
            dataIndex: ['audit', 'created'],
            key: 'auditCreated',
            align: 'center',
            width: 160,
            render: (value: any, announcement: Announcement) =>
                value && (
                    <Link to={`/councils/${council?.id}/announcements/${announcement.id}`}>
                        <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="announcement.bulletinPublished" />,
            dataIndex: 'bulletinPublished',
            key: 'bulletinPublished',
            align: 'center',
            width: 180,
            render: (value: any, announcement: Announcement) =>
                value && (
                    <Link to={`/councils/${council?.id}/announcements/${announcement.id}`}>
                        <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
                    </Link>
                )
        },
        {
            title: <FormattedMessage id="announcement.bulletinNumber" />,
            dataIndex: 'bulletinNumber',
            key: 'bulletinNumber',
            align: 'center',
            width: 160,
            render: (value: number, announcement: Announcement) => <Link to={`/councils/${council?.id}/announcements/${announcement.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="announcement.expired" />,
            dataIndex: 'expired',
            key: 'expired',
            align: 'center',
            width: 180,
            render: (value: any, announcement: Announcement) =>
                value && (
                    <Link to={`/councils/${council?.id}/announcements/${announcement.id}`}>
                        <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
                    </Link>
                )
        },

        {
            key: 'actions',
            width: 200,
            align: 'center',
            render: (announcement: Announcement) => (
                <Space>
                    <Tooltip title={<FormattedMessage id="announcements.download" />}>
                        <Button icon={<FilePdfOutlined />} className={styles.icon} onClick={() => downloadSigned(announcement)} type="link" />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="announcements.download.xml" />}>
                        <Button icon={<CloudDownloadOutlined />} className={styles.icon} onClick={() => downloadXml(announcement)} type="link" />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="announcements.download.bulletin" />}>
                        <Button
                            icon={<DownloadOutlined />}
                            className={styles.icon}
                            onClick={() => downloadBulletin(announcement)}
                            type="link"
                            disabled={!announcement.bulletinFile}
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="button.update" />}>
                        <Button
                            icon={<FormOutlined />}
                            className={styles.icon}
                            onClick={() => showAnnouncementUpdateModal(announcement)}
                            disabled={!announcement.treasurerSigned || !isAdmin}
                            type="link"
                        />
                    </Tooltip>
                </Space>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="users.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/announcements`, name: <FormattedMessage id="announcements.title" /> }
            ]}
            council={council}
        >
            <Form form={form} onFinish={filterPaymentOrderSigns} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={18}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'announcements.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6} className={styles.buttons}>
                        <Space>
                            <Link to={`/councils/${council?.id}`}>
                                <Button size="large" icon={<ArrowLeftOutlined />} />
                            </Link>
                            {isAdmin && (
                                <Tooltip title={<FormattedMessage id="announcements.new.tooltip" />}>
                                    <Button type="primary" size="large" icon={<PlusOutlined />} onClick={() => setAnnouncementModalVisible(true)}>
                                        {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                    </Button>
                                </Tooltip>
                            )}
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(announcementsPage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
            {announcement && announcementPublicationModal && <AnnouncementPublicationModal announcementId={announcement.id} onSave={save} onCancel={cancel} />},
            {announcementModalVisible && council && (
                <AnnouncementModal councilId={council.id!} onCancel={() => setAnnouncementModalVisible(false)} onSave={save} />
            )}
        </LayoutComponent>
    );
};

export default AnnouncementsPage;

interface Filter {
    searchText?: string | undefined;
}
