import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import statisticsApi from '../../../apis/StatisticsApi';
import { StatsReceivableTaxByTaxAndFiscalYearAndCreatedYear, StatsReceivableTaxByTaxAndFiscalYearAndCreatedYears } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import styles from './StatisticsReceivableByTaxFiscalYearCreatedYearsComponent.module.scss';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';

/**
 *
 * @param props the props
 * @returns the statistics receivables by fiscal year, creation year and tax component
 */
const StatisticsReceivableByTaxFiscalYearCreatedYearsComponent: React.FC<Props> = (props) => {
    const { councilId, startDate, endDate } = props;

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading'>();
    const [receivablesByTaxAndFiscalYearAndCreatedYears, setReceivablesByTaxAndFiscalYearAndCreatedYears] =
        useState<StatsReceivableTaxByTaxAndFiscalYearAndCreatedYears>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const receivablesByTaxAndFiscalYearAndCreatedYears = await statisticsApi.getReceivableTaxFiscalYearCreatedYears(councilId, startDate, endDate);
                setReceivablesByTaxAndFiscalYearAndCreatedYears(receivablesByTaxAndFiscalYearAndCreatedYears);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, endDate, intl, startDate]);

    /*** HOOKS ***/

    /*** METHODS ***/

    /*** VISUAL ***/

    const columns: ColumnsType<StatsReceivableTaxByTaxAndFiscalYearAndCreatedYear> = [
        {
            title: <FormattedMessage id="statsReceivableTaxByTaxAndFiscalYearAndCreatedYear.tax" />,
            dataIndex: 'tax',
            key: 'tax',

            render: (value: string, statsReceivableTaxByTaxAndFiscalYearAndCreatedYear: StatsReceivableTaxByTaxAndFiscalYearAndCreatedYear) => (
                <ConstantLabel value={value} prefix="receivable.tax." />
            )
        },
        {
            title: <FormattedMessage id="statsReceivableTaxByTaxAndFiscalYearAndCreatedYear.fiscalYear" />,
            dataIndex: 'fiscalYear',
            key: 'fiscalYear',
            align: 'right',
            width: 250
        },
        {
            title: <FormattedMessage id="statsReceivableTaxByTaxAndFiscalYearAndCreatedYear.createdYear" />,
            dataIndex: 'createdYear',
            key: 'createdYear',
            align: 'right',
            width: 250
        },

        {
            title: <FormattedMessage id="statsReceivableTaxByTaxAndFiscalYearAndCreatedYear.amount.principal" />,
            dataIndex: ['amount', 'principal'],
            key: 'amountPrincipal',
            align: 'right',
            width: 250,
            render: (value: number, statsReceivableTaxByTaxAndFiscalYearAndCreatedYear: StatsReceivableTaxByTaxAndFiscalYearAndCreatedYear) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },

        {
            title: <FormattedMessage id="statsReceivableTaxByTaxAndFiscalYearAndCreatedYear.count" />,
            dataIndex: 'count',
            key: 'count',
            align: 'right',
            width: 250
        }
    ];

    const statsReceivableTaxByTaxAndFiscalYearAndCreatedYears =
        receivablesByTaxAndFiscalYearAndCreatedYears?.statsReceivableTaxByTaxAndFiscalYearAndCreatedYears
            ? receivablesByTaxAndFiscalYearAndCreatedYears?.statsReceivableTaxByTaxAndFiscalYearAndCreatedYears
            : [];
    return (
        <div className={styles.container}>
            <Table
                className={styles.table}
                dataSource={statsReceivableTaxByTaxAndFiscalYearAndCreatedYears}
                columns={columns}
                pagination={false}
                rowKey="id"
                showSorterTooltip={false}
                loading={loading === 'loading'}
                summary={(pageData) => {
                    return (
                        <>
                            <Table.Summary.Row className={styles.summary}>
                                <Table.Summary.Cell index={0}>
                                    <FormattedMessage id="billingReceivablePayment.total" />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} />
                                <Table.Summary.Cell index={2} />
                                <Table.Summary.Cell index={3} align="right">
                                    {receivablesByTaxAndFiscalYearAndCreatedYears && receivablesByTaxAndFiscalYearAndCreatedYears.amount && (
                                        <FormattedNumber
                                            value={receivablesByTaxAndFiscalYearAndCreatedYears!.amount.principal}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4} align="right">
                                    {receivablesByTaxAndFiscalYearAndCreatedYears && receivablesByTaxAndFiscalYearAndCreatedYears.count && (
                                        <FormattedNumber value={receivablesByTaxAndFiscalYearAndCreatedYears!.count} />
                                    )}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </div>
    );
};
export default StatisticsReceivableByTaxFiscalYearCreatedYearsComponent;

interface Props {
    councilId: number;
    startDate: Dayjs;
    endDate: Dayjs;
}
