import { Page } from '../models/Elements';
import { CensusDocument, CensusTransfer, CensusUrbanProperty, ReceivableVoluntaryRegistrationUrbanProperty } from '../models/Entities';
import { StatusType } from '../models/Types';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class CensusUrbanPropertyApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId: number,
        searchText?: string,
        transferred?: boolean,
        status?: StatusType
    ): Promise<Page<CensusUrbanProperty>> => {
        const response = await adminApiAxios.get<Page<CensusUrbanProperty>>(`/census-urban-properties`, {
            params: { page, size, sortField, sortOrder, councilId, searchText, transferred, status }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<CensusUrbanProperty> => {
        const response = await adminApiAxios.get<CensusUrbanProperty>(`/census-urban-properties/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (censusUrbanProperty: CensusUrbanProperty): Promise<CensusUrbanProperty> => {
        const response = await adminApiAxios.post<CensusUrbanProperty>(`/census-urban-properties`, censusUrbanProperty);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (censusUrbanProperty: CensusUrbanProperty): Promise<CensusUrbanProperty> => {
        const response = await adminApiAxios.put<CensusUrbanProperty>(`/census-urban-properties/${censusUrbanProperty.id}`, censusUrbanProperty);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (id: number): Promise<void> => {
        const response = await adminApiAxios.delete<void>(`/census-urban-properties/${id}`);

        return response.data;
    };

    createResults = async (censusDocument: CensusDocument, file?: any): Promise<CensusUrbanProperty[]> => {
        const formData: FormData = this.buildFormData(censusDocument, file);
        const response = await adminApiAxios.post<CensusUrbanProperty[]>(`/census-urban-properties/results`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        response.data.forEach((item) => this.loadDates(item));

        return response.data;
    };

    transfer = async (censusTransfer: CensusTransfer): Promise<ReceivableVoluntaryRegistrationUrbanProperty[]> => {
        const response = await adminApiAxios.post<ReceivableVoluntaryRegistrationUrbanProperty[]>(`/census-urban-properties/transfers`, censusTransfer);
        response.data.forEach((item) => this.loadReceivableVoluntaryRegistrationUrbanPropertyDates(item));

        return response.data;
    };

    /**
     * Builds a form data from a census urban property document.
     * @param censusDocument the census document
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (censusDocument: CensusDocument, file?: File) => {
        const formData: FormData = new FormData();
        censusDocument.id && formData.append('id', censusDocument.id.toString());
        censusDocument.councilId && formData.append('councilId', censusDocument.councilId.toString());
        censusDocument.tax && formData.append('tax', censusDocument.tax.toString());
        censusDocument.index && formData.append('index', censusDocument.index.toString());
        censusDocument.year && formData.append('year', censusDocument.year.toString());
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Loads the dates of a census urban property.
     * @param censusUrbanProperty - the censusUrbanProperty
     */
    private loadDates(censusUrbanProperty: CensusUrbanProperty) {
        censusUrbanProperty.audit!.created = dateService.transformDate(censusUrbanProperty.audit!.created)!;
        censusUrbanProperty.audit!.updated = dateService.transformDate(censusUrbanProperty.audit!.updated)!;
    }

    /**
     * Loads the dates of a receivable census urban property.
     * @param receivable - the receivable census urban property
     */
    private loadReceivableVoluntaryRegistrationUrbanPropertyDates(receivableVoluntaryRegistrationUrbanProperty: ReceivableVoluntaryRegistrationUrbanProperty) {
        receivableVoluntaryRegistrationUrbanProperty.audit!.created = dateService.transformDate(receivableVoluntaryRegistrationUrbanProperty.audit!.created)!;
        receivableVoluntaryRegistrationUrbanProperty.audit!.updated = dateService.transformDate(receivableVoluntaryRegistrationUrbanProperty.audit!.updated)!;
    }
}

const censusUrbanPropertyApi: CensusUrbanPropertyApi = new CensusUrbanPropertyApi();
export default censusUrbanPropertyApi;
