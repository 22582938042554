import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import { Dayjs } from 'dayjs';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import billingApi from '../../../apis/BillingApi';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import { BillingPaymentsByFiscalYearCreatedYearTax, BillingPaymentsFiscalYearCreatedYearTax } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import styles from './BillingPaymentsByFiscalYearCreatedYearTaxComponent.module.scss';

/**
 *
 * @param props the props
 * @returns the billing payments by fiscal year, creation year and tax component
 */
const BillingPaymentsByFiscalYearCreatedYearTaxComponent: React.FC<Props> = (props) => {
    const { councilId, startDate, endDate } = props;

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading' | 'loading'>();
    const [paymentsByFiscalYearCreatedYearTax, setPaymentsByFiscalYearCreatedYearTax] = useState<BillingPaymentsByFiscalYearCreatedYearTax>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const paymentsByFiscalYearCreatedYearTax = await billingApi.getPaymentFiscalYearCreatedYearTax(councilId, startDate, endDate);
                setPaymentsByFiscalYearCreatedYearTax(paymentsByFiscalYearCreatedYearTax);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, endDate, intl, startDate]);

    /*** HOOKS ***/

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');

            const document = await billingApi.downloadPaymentFiscalYearCreatedYearTax(councilId, startDate, endDate);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'billingPaymentsFiscalYearCreatedYearTax.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const columns: ColumnsType<BillingPaymentsFiscalYearCreatedYearTax> = [
        {
            title: <FormattedMessage id="billingPaymentsFiscalYearCreatedYearTax.fiscalYear" />,
            dataIndex: 'fiscalYear',
            key: 'fiscalYear',
            align: 'center',
            width: 20
        },
        {
            title: <FormattedMessage id="billingPaymentsFiscalYearCreatedYearTax.createdYear" />,
            dataIndex: 'createdYear',
            key: 'createdYear',
            align: 'center',
            width: 20
        },
        {
            title: <FormattedMessage id="billingPaymentsFiscalYearCreatedYearTax.tax" />,
            dataIndex: 'tax',
            key: 'tax',
            align: 'left',
            render: (value: string, billingPaymentsFiscalYearCreatedYearTax: BillingPaymentsFiscalYearCreatedYearTax) => (
                <ConstantLabel value={value} prefix="receivable.tax." />
            )
        },

        {
            title: <FormattedMessage id="billingPaymentsFiscalYearCreatedYearTax.amount.principal" />,
            dataIndex: ['amount', 'principal'],
            key: 'amountPrincipal',
            align: 'right',
            width: 70,
            render: (value: number, billingPaymentsFiscalYearCreatedYearTax: BillingPaymentsFiscalYearCreatedYearTax) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingPaymentsFiscalYearCreatedYearTax.amount.surcharge" />,
            dataIndex: ['amount', 'surcharge'],
            key: 'amountSurcharge',
            align: 'right',
            width: 70,
            render: (value: number, billingPaymentsFiscalYearCreatedYearTax: BillingPaymentsFiscalYearCreatedYearTax) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingPaymentsFiscalYearCreatedYearTax.amount.interest" />,
            dataIndex: ['amount', 'interest'],
            key: 'amountInterest',
            align: 'right',
            width: 70,
            render: (value: number, billingPaymentsFiscalYearCreatedYearTax: BillingPaymentsFiscalYearCreatedYearTax) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },

        {
            title: <FormattedMessage id="billingPaymentsFiscalYearCreatedYearTax.costs" />,
            dataIndex: 'costs',
            key: 'costs',
            align: 'right',
            width: 70,
            render: (value: number, billingPaymentsFiscalYearCreatedYearTax: BillingPaymentsFiscalYearCreatedYearTax) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingPaymentsFiscalYearCreatedYearTax.total" />,
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            width: 70,
            render: (value: number, billingPaymentsFiscalYearCreatedYearTax: BillingPaymentsFiscalYearCreatedYearTax) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingPaymentsFiscalYearCreatedYearTax.count" />,
            dataIndex: 'count',
            key: 'count',
            align: 'right',
            width: 160
        }
    ];

    const billingPaymentsFiscalYearCreatedYearTax = paymentsByFiscalYearCreatedYearTax?.billingPaymentsFiscalYearCreatedYearTaxes
        ? paymentsByFiscalYearCreatedYearTax?.billingPaymentsFiscalYearCreatedYearTaxes
        : [];
    return (
        <div className={styles.container}>
            <Tooltip title={<FormattedMessage id="button.download" />}>
                <Button size="large" icon={<FileExcelOutlined />} onClick={download} loading={loading === 'downloading'}>
                    <FormattedMessage id="button.download" tagName="span" />
                </Button>
            </Tooltip>
            <Table
                className={styles.table}
                dataSource={billingPaymentsFiscalYearCreatedYearTax}
                columns={columns}
                pagination={false}
                rowKey="id"
                showSorterTooltip={false}
                loading={loading === 'loading'}
                summary={(pageData) => {
                    return (
                        <>
                            <Table.Summary.Row className={styles.summary}>
                                <Table.Summary.Cell index={0} align="center">
                                    <FormattedMessage id="billingReceivablePayment.total" />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} />
                                <Table.Summary.Cell index={2} />
                                <Table.Summary.Cell index={3} align="right">
                                    {paymentsByFiscalYearCreatedYearTax && paymentsByFiscalYearCreatedYearTax.amount && (
                                        <FormattedNumber
                                            value={paymentsByFiscalYearCreatedYearTax!.amount.principal}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4} align="right">
                                    {paymentsByFiscalYearCreatedYearTax && paymentsByFiscalYearCreatedYearTax.amount && (
                                        <FormattedNumber
                                            value={paymentsByFiscalYearCreatedYearTax!.amount.surcharge}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={5} align="right">
                                    {paymentsByFiscalYearCreatedYearTax && paymentsByFiscalYearCreatedYearTax.amount && (
                                        <FormattedNumber
                                            value={paymentsByFiscalYearCreatedYearTax!.amount.interest}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={6} align="right">
                                    {paymentsByFiscalYearCreatedYearTax && paymentsByFiscalYearCreatedYearTax.costs && (
                                        <FormattedNumber
                                            value={paymentsByFiscalYearCreatedYearTax!.costs}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={7} align="right">
                                    {paymentsByFiscalYearCreatedYearTax && paymentsByFiscalYearCreatedYearTax.total && (
                                        <FormattedNumber
                                            value={paymentsByFiscalYearCreatedYearTax!.total}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={8} align="right">
                                    {paymentsByFiscalYearCreatedYearTax && paymentsByFiscalYearCreatedYearTax.count && (
                                        <FormattedNumber value={paymentsByFiscalYearCreatedYearTax!.count} />
                                    )}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </div>
    );
};
export default BillingPaymentsByFiscalYearCreatedYearTaxComponent;

interface Props {
    councilId: number;
    startDate: Dayjs;
    endDate: Dayjs;
}
