import { CloudDownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib';
import FileSaver from 'file-saver';
import { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import taxpayerVersionApi from '../../../../apis/TaxpayerVersionApi';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomTaxpayerContext from '../../../../contexts/CustomTaxpayerContext';
import { BreadcrumbItem, Page } from '../../../../models/Elements';
import { Taxpayer, TaxpayerVersion } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import TaxpayerVersionModal from '../TaxPayerVersionModal/TaxpayerVersionModal';

/**
 * Returns the taxpayer versions page.
 * @returns the taxpayer versions page.
 */
const TaxpayerVersionsPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const { taxpayer } = useContext(CustomTaxpayerContext);
    const [taxpayerVersions, setTaxpayerVersions] = useState<Page<TaxpayerVersion>>();
    const [sortField, setSortField] = useState<string>('auditCreated');
    const [page, setPage] = useState<number>(0);
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [loading, setLoading] = useState<'downloading' | 'loading' | 'saving'>();
    const [taxpayerVersion, setTaxpayerVersion] = useState<TaxpayerVersion>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                if (taxpayer.identifier) {
                    const taxpayerId = taxpayer.id;
                    setLoading('loading');
                    const taxpayerVersions = await taxpayerVersionApi.list(page, 1000000, sortField, sortOrder, taxpayerId!);
                    setTaxpayerVersions(taxpayerVersions);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, taxpayer]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const download = async (taxpayerVersion: TaxpayerVersion) => {
        try {
            setLoading('downloading');
            const document = await taxpayerVersionApi.download(taxpayerVersion.id!);
            FileSaver.saveAs(document, taxpayerVersion.attachment?.name);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const items = taxpayerVersions ? taxpayerVersions.content : [];
    const columns: ColumnsType<TaxpayerVersion> = [
        {
            title: <FormattedMessage id="taxpayerVersion.status" />,
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 100,
            render: (value: string, taxpayerVersion: TaxpayerVersion) => <ConstantLabel value={value} prefix="taxpayerVersion.status." />
        },
        {
            title: <FormattedMessage id="audit.changed" />,
            dataIndex: ['audit', 'created'],
            key: 'created',
            sorter: true,
            align: 'center',
            width: 180,
            render: (value: any, taxpayerVersion: TaxpayerVersion) => (
                <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
            )
        },
        {
            title: <FormattedMessage id="taxpayerVersion.remarks" />,
            dataIndex: 'remarks',
            key: 'remarks',
            sorter: true,
            width: 200,
            ellipsis: {
                showTitle: false
            },
            render: (remarks) => (
                <Tooltip placement="topLeft" title={remarks}>
                    {remarks}
                </Tooltip>
            )
        },
        {
            title: <FormattedMessage id="taxpayerVersion.attachment" />,
            dataIndex: 'attachment',
            key: 'attachment',
            sorter: true,
            align: 'center',
            width: 160,
            render: (value: any, taxpayerVersion: TaxpayerVersion) =>
                value !== undefined && (
                    <Button size="large" type="link" icon={<CloudDownloadOutlined />} onClick={() => download(taxpayerVersion)}>
                        {value.name}
                    </Button>
                )
        },
        {
            title: <FormattedMessage id="taxpayerVersion.action" />,
            dataIndex: 'action',
            key: 'action',
            sorter: true,
            align: 'center',
            width: 160,
            render: (value: any, taxpayerVersion: TaxpayerVersion) =>
                value !== undefined && (
                    <Button size="large" icon={<EyeOutlined />} onClick={() => setTaxpayerVersion(taxpayerVersion)}>
                        <span />
                        <FormattedMessage id="button.view" />
                    </Button>
                )
        }
    ];

    return (
        <LayoutComponent title={<FormattedMessage id="receivables.title" />} menu={'taxpayers'} path={PathComponent(taxpayer)}>
            <Table
                dataSource={items}
                columns={columns}
                pagination={false}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
            {taxpayerVersion && <TaxpayerVersionModal taxpayerVersion={taxpayerVersion} onCancel={() => setTaxpayerVersion(undefined)} />}
        </LayoutComponent>
    );
};
export default TaxpayerVersionsPage;

/**
 * Returns the path of the taxpayer versions page.
 * @param taxpayer the taxpayer
 * @returns  the path
 */
const PathComponent = (taxpayer: Taxpayer): BreadcrumbItem[] => {
    return [
        { path: '/taxpayers', name: <FormattedMessage id="taxpayers.title" /> },
        { path: `/taxpayers/${taxpayer.id}`, name: taxpayer?.fullName },
        { path: `/taxpayers/${taxpayer.id}/history`, name: <FormattedMessage id="taxpayer.history" /> }
    ];
};
