import { Col, Form, Row, Space, Table, TablePaginationConfig, Tag } from 'antd';
import Search from 'antd/es/input/Search';
import Select, { DefaultOptionType } from 'antd/es/select';
import { TableRowSelection } from 'antd/es/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { Key, useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import receivableApi from '../../../../apis/ReceivableApi';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';
import CouncilTaxpayerReceivableActionsMenu from '../../../../components/CouncilTaxpayerReceivableActionsMenu/CouncilTaxpayerReceivableActionsMenu';
import CustomContext from '../../../../contexts/CustomContext';
import CustomCouncilContext from '../../../../contexts/CustomCouncilContext';
import CustomCouncilTaxpayerContext from '../../../../contexts/CustomCouncilTaxpayerContext';
import { Receivable, ReceivableFilter } from '../../../../models/Entities';
import { PhaseType, StateType, stateTypes, TaxType, taxTypes } from '../../../../models/Types';
import alertService from '../../../../services/AlertService';
import receivableService from '../../../../services/ReceivableService';
import rolesService from '../../../../services/RolesService';
import styles from './CouncilTaxpayerReceivablesComponent.module.scss';

/**
 * The council taxpayer receivables component with the receivable information.
 * @param props the props
 * @returns council taxpayer receivables component
 */
const CouncilTaxpayerReceivablesComponent: React.FC<CouncilTaxpayerReceivablesComponentProps> = (props) => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm<ReceivableFilter>();
    const { auth } = useContext(CustomContext);
    const { council } = useContext(CustomCouncilContext);
    const { councilTaxpayer } = useContext(CustomCouncilTaxpayerContext);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [loading, setLoading] = useState<'loading' | 'saving'>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('id');
    const [sortOrder, setSortOrder] = useState<boolean>(true);
    const [filter, setFilter] = useState<ReceivableFilter>();
    const [receivables, setReceivables] = useState<Receivable[]>();

    /*** EFFECTS ***/

    // initialize filter
    useEffect(() => {
        const init = async () => {
            if (council.id && councilTaxpayer) {
                let filter: ReceivableFilter = { status: 'ENABLED', councilId: council.id, taxpayerIdentifier: councilTaxpayer.taxpayer.identifier };
                if (rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])) {
                    filter = Object.assign({}, filter, { states: stateTypes.filter((s) => !['PAID', 'CANCELLED'].includes(s)) });
                } else {
                    filter = Object.assign({}, filter, { states: stateTypes.filter((s) => ['NOTIFICATION_BUILD'].includes(s)) });
                }
                setFilter(filter);
                form.setFieldsValue({ states: filter.states });
            }
        };
        init();
    }, [auth, council, form, councilTaxpayer]);

    //load receivables

    useEffect(() => {
        const init = async () => {
            try {
                if (filter && filter.taxpayerIdentifier) {
                    setLoading('loading');
                    const receivablePage = await receivableApi.list(page, 1000000, sortField, sortOrder, filter);
                    setReceivables(receivablePage.content);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, sortField, sortOrder, filter, page]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const search = async (values: ReceivableFilter) => {
        const updatedFilter: ReceivableFilter = Object.assign({}, filter, values);
        setFilter(updatedFilter);
    };

    const select = (selectedRowKeys: Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const refresh = async () => {
        try {
            const receivablePage = await receivableApi.list(page, 1000000, sortField, sortOrder, filter);
            setReceivables(receivablePage.content);
            props.onRefresh();
        } catch (error) {
            alertService.displayError(error, intl);
        }
    };

    /*** VISUAL ***/
    const rowSelection: TableRowSelection<Receivable> = {
        selectedRowKeys,
        onChange: select,
        columnWidth: 20
    };
    const stateOptions: DefaultOptionType[] = [
        {
            label: <FormattedMessage id="receivable.phase.VOLUNTARY" />,
            options: receivableService.voluntaryStates.map((s) => ({
                label: <ConstantLabel value={s} prefix="receivable.state." />,
                value: s
            }))
        },
        {
            label: <FormattedMessage id="receivable.phase.APREMIO" />,
            options: receivableService.apremioStates.map((s) => ({
                label: <ConstantLabel value={s} prefix="receivable.state." />,
                value: s
            }))
        },
        {
            label: <FormattedMessage id="receivable.phase.SEIZURE" />,
            options: receivableService.seizureStates.map((s) => ({
                label: <ConstantLabel value={s} prefix="receivable.state." />,
                value: s
            }))
        },
        {
            label: <FormattedMessage id="receivable.phase.FINAL" />,
            options: receivableService.finalStates.map((s) => ({
                label: <ConstantLabel value={s} prefix="receivable.state." />,
                value: s
            }))
        }
    ];
    const taxOptions = taxTypes.map((tt) => ({ value: tt, label: <ConstantLabel value={tt} prefix="receivable.tax.short." /> }));
    const items = receivables ? receivables : [];
    const selectedItems = items.filter((item) => selectedRowKeys.includes(item.id as Key));
    const columns: ColumnsType<Receivable> = [
        {
            title: <FormattedMessage id="receivable.reference.short" />,
            dataIndex: 'reference',
            key: 'id',
            width: 100,
            align: 'center',
            render: (value: string, receivable: Receivable) => (
                <Link to={`/councils/${council.id}/taxpayers/${councilTaxpayer?.id}/receivables/${receivable.id}`}>{value} </Link>
            )
        },
        {
            title: <FormattedMessage id="receivable.name" />,
            dataIndex: 'tax',
            key: 'tax',
            align: 'center',
            render: (value: TaxType, receivable: Receivable) => (
                <Link to={`/councils/${council.id}/taxpayers/${councilTaxpayer?.id}/receivables/${receivable.id}`}>
                    <ConstantLabel value={value} prefix="receivable.tax.short." />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="receivable.taxObject" />,
            dataIndex: 'taxObject',
            key: 'taxObject',
            align: 'center',
            render: (value: TaxType, receivable: Receivable) => (
                <Link to={`/councils/${council.id}/taxpayers/${councilTaxpayer?.id}/receivables/${receivable.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="receivable.fiscalYear" />,
            dataIndex: 'fiscalYear',
            key: 'fiscalYear',
            align: 'center',
            width: 120,
            render: (value: number, receivable: Receivable) => (
                <Link to={`/councils/${council.id}/taxpayers/${councilTaxpayer?.id}/receivables/${receivable.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="amount.principal" />,
            dataIndex: ['amount', 'principal'],
            key: 'principal',
            align: 'right',
            width: 200,
            render: (value: number, receivable: Receivable) => (
                <Link to={`/councils/${council.id}/taxpayers/${councilTaxpayer?.id}/receivables/${receivable.id}`}>
                    <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="amount.surcharge" />,
            dataIndex: ['amount', 'surcharge'],
            key: 'surcharge',
            align: 'right',
            width: 170,
            render: (value: number, receivable: Receivable) => (
                <Link to={`/councils/${council.id}/taxpayers/${councilTaxpayer?.id}/receivables/${receivable.id}`}>
                    <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="amount.interest" />,
            dataIndex: ['amount', 'interest'],
            key: 'interest',
            align: 'right',
            width: 170,
            render: (value: number, receivable: Receivable) => (
                <Link to={`/councils/${council.id}/taxpayers/${councilTaxpayer?.id}/receivables/${receivable.id}`}>
                    <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="councilTaxpayer.costs" />,
            dataIndex: ['amount'],
            key: 'costs',
            align: 'right',
            width: 100,
            render: (value: number, receivable: Receivable) => (
                <Link to={`/councils/${council.id}/taxpayers/${councilTaxpayer?.id}/receivables/${receivable.id}`}>-</Link>
            )
        },
        {
            title: <FormattedMessage id="amount.total" />,
            dataIndex: ['amount', 'total'],
            key: 'total',
            align: 'right',
            width: 100,
            render: (value: number, receivable: Receivable) => (
                <Link to={`/councils/${council.id}/taxpayers/${councilTaxpayer?.id}/receivables/${receivable.id}`} className={styles.link}>
                    <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="receivable.phase" />,
            dataIndex: 'phase',
            key: 'phase',
            align: 'center',
            width: 120,
            render: (value: PhaseType, receivable: Receivable) => (
                <Link to={`/councils/${council.id}/taxpayers/${councilTaxpayer?.id}/receivables/${receivable.id}`}>
                    <Tag color={value === 'APREMIO' ? 'orange' : 'red'} className="tag">
                        <ConstantLabel value={value} prefix="receivable.phase." />
                    </Tag>
                </Link>
            )
        },
        {
            title: <FormattedMessage id="receivable.state" />,
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            render: (value: StateType, receivable: Receivable) => (
                <Link to={`/councils/${council.id}/taxpayers/${councilTaxpayer?.id}/receivables/${receivable.id}`}>
                    <ConstantLabel value={value} prefix="receivable.state." />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="audit.created" />,
            dataIndex: 'auditCreated',
            key: 'auditCreated',
            sorter: true,
            align: 'center',
            width: 200,
            render: (value: any, receivable: Receivable) => (
                <Link to={`/councils/${council.id}/taxpayers/${councilTaxpayer?.id}/receivables/${receivable.id}`}>
                    <FormattedDate value={receivable.audit!.created! as any} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
                </Link>
            )
        }
    ];

    return (
        <>
            <Form form={form} onFinish={search} colon={false} layout="vertical" requiredMark={false} className={styles.form}>
                <Row gutter={[0, 0]}>
                    <Col span={18}>
                        <Space className={styles.filter}>
                            <Form.Item name="searchText">
                                <Search
                                    placeholder={intl.formatMessage({
                                        id: 'receivables.search'
                                    })}
                                    size="large"
                                    allowClear
                                    className={styles.search}
                                    onSearch={form.submit}
                                />
                            </Form.Item>
                            <Form.Item name="tax">
                                <Select
                                    placeholder={intl.formatMessage({
                                        id: 'receivables.filter.tax'
                                    })}
                                    size="large"
                                    className={styles.tax}
                                    allowClear
                                    maxTagCount={'responsive'}
                                    onChange={form.submit}
                                    options={[...taxOptions]}
                                />
                            </Form.Item>
                        </Space>
                    </Col>
                    <Col span={6} className={styles.buttons}>
                        <Space>
                            {councilTaxpayer && (
                                <CouncilTaxpayerReceivableActionsMenu receivables={selectedItems} councilTaxpayer={councilTaxpayer} onAction={refresh} />
                            )}
                        </Space>
                    </Col>
                    <Col span={18}>
                        <Form.Item name="states">
                            <Select
                                placeholder={intl.formatMessage({
                                    id: 'receivables.filter.state'
                                })}
                                size="large"
                                className={styles.state}
                                allowClear
                                maxTagCount={'responsive'}
                                onChange={form.submit}
                                mode="multiple"
                                options={[...stateOptions]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={false}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className={`table ${styles.table}`}
                rowSelection={rowSelection}
                summary={(pageData) => {
                    const totalAmount = pageData.map((t) => t.amount!.total).reduce((partialSum, a) => partialSum + a, 0);
                    const totalPrincipal = pageData.map((p) => p.amount!.principal).reduce((partialSum, a) => partialSum + a, 0);
                    const totalSurcharge = pageData.map((s) => s.amount!.surcharge).reduce((partialSum, a) => partialSum + a, 0);
                    const totalInterest = pageData.map((s) => s.amount!.interest).reduce((partialSum, a) => partialSum + a, 0);
                    const totalCosts = councilTaxpayer ? councilTaxpayer.costs : 0;
                    const subtotalAmount = pageData
                        .filter((t) => selectedRowKeys.includes(t.id!))
                        .map((t) => t.amount!.total)
                        .reduce((partialSum, a) => partialSum + a, 0);
                    const subtotalPrincipal = pageData
                        .filter((t) => selectedRowKeys.includes(t.id!))
                        .map((t) => t.amount!.principal)
                        .reduce((partialSum, a) => partialSum + a, 0);
                    const subtotalSurcharge = pageData
                        .filter((t) => selectedRowKeys.includes(t.id!))
                        .map((t) => t.amount!.surcharge)
                        .reduce((partialSum, a) => partialSum + a, 0);
                    const subtotalInterest = pageData
                        .filter((t) => selectedRowKeys.includes(t.id!))
                        .map((t) => t.amount!.interest)
                        .reduce((partialSum, a) => partialSum + a, 0);
                    const subtotalCosts = councilTaxpayer ? councilTaxpayer.costs : 0;

                    return (
                        <>
                            <Table.Summary.Row className={styles.summary}>
                                <Table.Summary.Cell index={0} align="center">
                                    <FormattedMessage id="receivable.amount.total" />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} />
                                <Table.Summary.Cell index={2} />
                                <Table.Summary.Cell index={3} />
                                <Table.Summary.Cell index={4} />

                                <Table.Summary.Cell index={5} align="right">
                                    <FormattedNumber
                                        value={totalPrincipal}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={6} align="right">
                                    <FormattedNumber
                                        value={totalSurcharge}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={7} align="right">
                                    <FormattedNumber
                                        value={totalInterest}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={8} align="right">
                                    <FormattedNumber
                                        value={totalCosts}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={9} align="right" className={styles.total}>
                                    <FormattedNumber
                                        value={totalAmount}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                <Table.Summary.Cell index={11}></Table.Summary.Cell>
                                <Table.Summary.Cell index={12}></Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row className={styles.selectedSummary}>
                                <Table.Summary.Cell index={0} align="center">
                                    <FormattedMessage id="receivable.amount.selected.total" />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} />
                                <Table.Summary.Cell index={2} />
                                <Table.Summary.Cell index={3} />
                                <Table.Summary.Cell index={4} />
                                <Table.Summary.Cell index={5} align="right">
                                    <FormattedNumber
                                        value={subtotalPrincipal}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={6} align="right">
                                    <FormattedNumber
                                        value={subtotalSurcharge}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={7} align="right">
                                    <FormattedNumber
                                        value={subtotalInterest}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={8} align="right">
                                    <FormattedNumber
                                        value={subtotalCosts}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={9} align="right" className={styles.total}>
                                    <FormattedNumber
                                        value={subtotalAmount}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                <Table.Summary.Cell index={11}></Table.Summary.Cell>
                                <Table.Summary.Cell index={12}></Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </>
    );
};
export default CouncilTaxpayerReceivablesComponent;

interface CouncilTaxpayerReceivablesComponentProps {
    onRefresh: () => void;
}
