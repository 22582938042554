import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, PaperClipOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Image, Input, List, Modal, Row, Upload, UploadFile, message } from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import TextArea from 'antd/es/input/TextArea';
import { Space } from 'antd/lib';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import taxpayerApi from '../../../apis/TaxpayerApi';
import FileSizeComponent from '../../../components/FileSizeComponent/FileSizeComponent';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import TaxpayerAddressComponent from '../../../components/TaxpayerAddressComponent/TaxpayerAddressComponent';
import CustomTaxpayerContext from '../../../contexts/CustomTaxpayerContext';
import { Taxpayer, TaxpayerVersion, TaxpayerWithVersion } from '../../../models/Entities';
import banks from '../../../resources/images/banks.svg';
import certificates from '../../../resources/images/certificates.svg';
import alertService from '../../../services/AlertService';
import styles from './TaxpayerPage.module.scss';

/**
 * Returns the taxpayer page.
 * @returns the taxpayer page.
 */
const TaxpayerPage: React.FC = () => {
    /***HOOKS***/
    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm<Taxpayer>();
    const navigate = useNavigate();
    const params = useParams<ParamsType>();
    const taxpayerContext = useContext(CustomTaxpayerContext);
    const [loading, setLoading] = useState<'loading' | 'saving' | 'deleting'>();
    const [saveModalVisible, setSaveModalVisible] = useState<boolean>(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [taxpayer, setTaxpayer] = useState<Taxpayer>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                let taxpayer: Taxpayer;
                if (taxpayerContext.taxpayer && taxpayerContext.taxpayer.id) {
                    taxpayer = taxpayerContext.taxpayer;
                } else {
                    taxpayer = { status: 'ENABLED' };
                }
                form.setFieldsValue(taxpayer);
                setTaxpayer(taxpayer);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [taxpayerContext.taxpayer, form, intl]);

    /*** METHODS ***/

    const showSaveModal = async (values: Taxpayer) => {
        const updatedTaxpayer: Taxpayer = Object.assign({}, taxpayer, values);
        setTaxpayer(updatedTaxpayer);

        setSaveModalVisible(true);
    };

    const save = async (taxpayerVersion: TaxpayerVersion, attachment?: UploadFile) => {
        try {
            setLoading('saving');

            if (taxpayer && taxpayerVersion) {
                const taxpayerWithVersion: TaxpayerWithVersion = {
                    taxpayer: taxpayer,
                    version: taxpayerVersion
                };

                let updatedTaxpayer: Taxpayer;
                if (taxpayerWithVersion.taxpayer.id) {
                    updatedTaxpayer = await taxpayerApi.update(taxpayerWithVersion, attachment);
                } else {
                    updatedTaxpayer = await taxpayerApi.create(taxpayerWithVersion, attachment);
                }
                setTaxpayer(updatedTaxpayer);
                setSaveModalVisible(false);

                message.success(intl.formatMessage({ id: 'status.saved' }));
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const remove = async (taxpayerVersion: TaxpayerVersion, attachment?: UploadFile) => {
        try {
            setLoading('deleting');
            if (taxpayer && taxpayer.id) {
                await taxpayerApi.delete(taxpayer.id, taxpayerVersion, attachment);
                message.success(intl.formatMessage({ id: 'status.deleted' }));
                navigate('/taxpayers');
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="taxpayer.title" />}
            menu="taxpayers"
            path={[
                { path: '/taxpayers', name: <FormattedMessage id="taxpayers.title" /> },
                {
                    path: `/taxpayers/${taxpayer && taxpayer.id ? taxpayer.id : params.id}`,
                    name: taxpayer && taxpayer.id && taxpayer.fullName
                }
            ]}
        >
            <Form form={form} onFinish={showSaveModal} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col lg={4}>
                        <Form.Item
                            label={<FormattedMessage id="taxpayer.identifier" />}
                            name="identifier"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^\d{8}(?![IÑOU])[A-Z]$|^[XYZ]{1}\d{7}(?![IÑOU])[A-Z]$|^[ABCDEFGHJNPQRSUVW]{1}\d{7}(?:[A-Z0-9])/,
                                    message: <FormattedMessage id="status.taxpayer.identifier.invalid" />
                                }
                            ]}
                        >
                            <Input size="large" maxLength={9} />
                        </Form.Item>
                    </Col>
                    <Col lg={8}>
                        <Form.Item
                            label={
                                taxpayer?.legalEntity !== 'JURIDICAL' ? (
                                    <FormattedMessage id="taxpayer.firstName" />
                                ) : (
                                    <FormattedMessage id="taxpayer.companyName" />
                                )
                            }
                            name="firstName"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col lg={6} hidden={taxpayer?.legalEntity === 'JURIDICAL'}>
                        <Form.Item label={<FormattedMessage id="taxpayer.lastName" />} name="lastName">
                            <Input size="large" maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col lg={6} hidden={taxpayer?.legalEntity === 'JURIDICAL'}>
                        <Form.Item label={<FormattedMessage id="taxpayer.secondLastName" />} name="secondLastName">
                            <Input size="large" maxLength={100} />
                        </Form.Item>
                    </Col>
                </Row>
                <TaxpayerMenu taxpayer={taxpayer} />
                <TaxpayerAddressComponent field={'address'} hideHeader />
                <Row gutter={[28, 0]} className="buttons">
                    <Col span={16}>
                        <Space>
                            <Button type="primary" htmlType="submit" size="large" icon={<SaveOutlined />}>
                                <FormattedMessage id="button.save" tagName="span" />
                            </Button>
                            {taxpayer?.id !== undefined && (
                                <Button type="primary" danger size="large" onClick={() => setDeleteModalVisible(true)} icon={<DeleteOutlined />}>
                                    {desktop && <FormattedMessage id="button.delete" tagName="span" />}
                                </Button>
                            )}
                            <Link to="/taxpayers">
                                <Button size="large" icon={<ArrowLeftOutlined />}>
                                    {desktop && <FormattedMessage id="button.back" tagName="span" />}
                                </Button>
                            </Link>
                        </Space>
                    </Col>
                </Row>
            </Form>
            {saveModalVisible && <ConfirmationModal type="save" loading={loading} onConfirm={save} onCancel={() => setSaveModalVisible(false)} />}
            {deleteModalVisible && <ConfirmationModal type="delete" loading={loading} onConfirm={remove} onCancel={() => setDeleteModalVisible(false)} />}
        </LayoutComponent>
    );
};
export default TaxpayerPage;
type ParamsType = { id: string };

/**
 * Returns the taxpayer menu.
 * @returns the taxpayer menu.
 */
const TaxpayerMenu: React.FC<TaxpayerMenuProps> = (props) => {
    const { taxpayer } = props;

    return (
        <div className={styles.menu}>
            <Row className={styles.cards} gutter={[28, 28]} justify="center">
                <Col xs={24} md={6}>
                    <Link to={`/taxpayers/${taxpayer?.id}/history`}>
                        <Card className={styles.item} cover={<Image className={styles.image} src={certificates} preview={false} />}>
                            <Card.Meta title={<FormattedMessage id="taxpayer.history" />} description={<FormattedMessage id="taxpayer.history.desc" />} />
                        </Card>
                    </Link>
                </Col>
                <Col xs={24} md={6}>
                    <Link to={`/taxpayers/${taxpayer?.id}/bank-branches`} className={styles.inactive}>
                        <Card className={styles.item} cover={<Image className={styles.image} src={banks} preview={false} />}>
                            <Card.Meta
                                title={<FormattedMessage id="taxpayer.bankBranches" />}
                                description={<FormattedMessage id="taxpayer.bankBranches.desc" />}
                            />
                        </Card>
                    </Link>
                </Col>
            </Row>
        </div>
    );
};
interface TaxpayerMenuProps {
    taxpayer: Taxpayer | undefined;
}

/**
 * Returns the taxpayer confirmation modal.
 * @returns the taxpayer confirmation modal
 */
const ConfirmationModal = (props: ConfirmationModalProps): React.ReactElement => {
    const { type, loading, onConfirm, onCancel } = props;
    const intl = useIntl();
    const [form] = Form.useForm();
    const [files, setFiles] = useState<UploadFile[]>([]);

    /*** METHODS */

    const confirm = async () => {
        try {
            const values = await form.validateFields();
            const taxpayerVersion: TaxpayerVersion = Object.assign({}, values);
            const attachment: UploadFile | undefined = files.length > 0 ? files[0] : undefined;
            onConfirm(taxpayerVersion, attachment);
        } catch (error: any) {
            if (!error.errorFields) {
                alertService.displayError(error, intl);
            }
        }
    };

    const uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!isFileSizeValid(file)) {
            setFiles(files);
        } else {
            files.push(file);
            setFiles(files);
        }

        return false;
    };

    const removeFile = () => {
        const files: UploadFile[] = [];
        form.setFieldsValue({
            file: files
        });
        setFiles(files);
    };

    const validateFile = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void): Promise<void> | void => {
        if (value && value.file && !isFileSizeValid(value.file)) {
            callback(intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };
    const maxFileSize = 5 * 1024 * 1024;
    const isFileSizeValid = (file: UploadFile) => !file.size || file.size <= maxFileSize;

    /*** COMPONENTS */

    return (
        <Modal
            title={<FormattedMessage id={`taxpayer.confirmation.modal.title.${type}`} />}
            open={true}
            width={800}
            onCancel={onCancel}
            onOk={confirm}
            okButtonProps={{ loading: loading === 'saving' || loading === 'deleting' }}
        >
            <Form form={form} colon={false} layout="vertical">
                <Row gutter={[28, 0]} className={styles.saveModal}>
                    <Col span={24}>
                        <Form.Item
                            name="remarks"
                            label={<FormattedMessage id="taxpayer.confirmation.modal.remarks" />}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <TextArea size="large" maxLength={800} rows={3} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col lg={12}>
                        <Form.Item
                            name="file"
                            valuePropName="files"
                            label={
                                <span>
                                    <FormattedMessage id="taxpayer.confirmation.modal.attachment" />
                                </span>
                            }
                            rules={[
                                {
                                    validator: validateFile
                                }
                            ]}
                            extra={
                                <>
                                    <FormattedMessage id="attachment.size" /> <FileSizeComponent value={maxFileSize} />
                                </>
                            }
                        >
                            <Upload.Dragger beforeUpload={uploadFile} fileList={files} showUploadList={false}>
                                <CloudUploadOutlined /> <FormattedMessage id="taxpayer.confirmation.modal.attachment.button" />
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <List
                            className={styles.files}
                            itemLayout="horizontal"
                            dataSource={files}
                            locale={{ emptyText: <></> }}
                            renderItem={(file) => (
                                <List.Item actions={[<Button icon={<DeleteOutlined />} danger size="large" onClick={removeFile} />]}>
                                    <List.Item.Meta
                                        avatar={<PaperClipOutlined className={styles.icon} />}
                                        title={file.name}
                                        description={<FileSizeComponent value={file.size} />}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

interface ConfirmationModalProps {
    loading?: 'loading' | 'saving' | 'deleting';
    type: 'save' | 'delete';
    onConfirm: (taxpayerVersion: TaxpayerVersion, attachment?: UploadFile) => Promise<void>;
    onCancel: () => void;
}
