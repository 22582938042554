import { Page } from '../models/Elements';
import { Payment, PaymentCreation, PaymentDraft } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class PaymentApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId?: number,
        councilTaxpayerId?: number,
        searchText?: string
    ): Promise<Page<Payment>> => {
        const response = await adminApiAxios.get<Page<Payment>>('/payments', {
            params: { page, size, sortField, sortOrder, councilId, councilTaxpayerId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach(this.loadDates);

        return response.data;
    };

    get = async (id: number): Promise<Payment> => {
        const response = await adminApiAxios.get<Payment>(`/payments/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (paymentCreation: PaymentCreation, file: any): Promise<Payment> => {
        const formData: FormData = this.buildFormData(paymentCreation, file);
        const response = await adminApiAxios.post<Payment>('/payments', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    createDraft = async (paymentCreation: PaymentCreation): Promise<PaymentDraft> => {
        const response = await adminApiAxios.post<PaymentDraft>('/payments/draft', paymentCreation);
        this.loadDraftDates(response.data);

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/payments/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Builds a form data from a payment creation.
     * @param paymentCreation the payment creation
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (paymentCreation: PaymentCreation, file?: File) => {
        const formData: FormData = new FormData();
        paymentCreation.receivableIds && formData.append('receivableIds', paymentCreation.receivableIds.toString());
        paymentCreation.councilTaxpayerId && formData.append('councilTaxpayerId', paymentCreation.councilTaxpayerId.toString());
        paymentCreation.councilCollectionAccountId && formData.append('councilCollectionAccountId', paymentCreation.councilCollectionAccountId.toString());
        paymentCreation.paid && formData.append('paid', paymentCreation.paid.format('YYYY-MM-DDThh:mm:ss'));
        paymentCreation.amount && formData.append('amount', paymentCreation.amount.toString());
        paymentCreation.paymentOrderId && formData.append('paymentOrderId', paymentCreation.paymentOrderId.toString());
        paymentCreation.paymentLetterId && formData.append('paymentLetterId', paymentCreation.paymentLetterId.toString());
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Loads the dates of a payment.
     * @param payment - the payment
     */
    private loadDates(payment: Payment) {
        payment.paid = dateService.transformDate(payment.paid)!;
        payment.audit!.created = dateService.transformDate(payment.audit!.created)!;
        payment.audit!.updated = dateService.transformDate(payment.audit!.updated)!;
    }

    /**
     * Loads the dates of the payment   draft.
     * @param paymentDraft - the payment   draft
     */
    private loadDraftDates(paymentDraft: PaymentDraft) {
        const { payment, receivablePayments } = paymentDraft;

        this.loadDates(payment);
        receivablePayments.forEach((receivablePayment) => {
            receivablePayment.audit!.created = dateService.transformDate(receivablePayment.audit!.created)!;
            receivablePayment.audit!.updated = dateService.transformDate(receivablePayment.audit!.updated)!;
        });
    }
}

const paymentApi: PaymentApi = new PaymentApi();
export default paymentApi;
