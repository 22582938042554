import { ColumnsType } from 'antd/es/table';
import { FileExcelOutlined } from '@ant-design/icons';
import { Table } from 'antd/lib';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import billingApi from '../../../apis/BillingApi';
import { BillingReceivableByCharge, BillingReceivablesCharge } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import styles from './InvoicesChargesComponent.module.scss';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import { Button, Tooltip } from 'antd';
import FileSaver from 'file-saver';

/**
 *
 * @param props the props
 * @returns the invoices charges component
 */
const InvoicesChargesComponent: React.FC<Props> = (props) => {
    const { councilId, startDate, endDate } = props;

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading' | 'loading'>();
    const [charges, setCharges] = useState<BillingReceivableByCharge>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const charges = await billingApi.getCharges(councilId, startDate, endDate);
                setCharges(charges);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, endDate, intl, startDate]);

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');

            const document = await billingApi.downloadCharges(councilId, startDate, endDate);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'invoiceCharges.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const columns: ColumnsType<BillingReceivablesCharge> = [
        {
            title: <FormattedMessage id="billingReceivablesCharge.index" />,
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            width: 50
        },
        {
            title: <FormattedMessage id="billingReceivablesCharge.chargeNumber" />,
            dataIndex: 'chargeNumber',
            key: 'chargeNumber',
            align: 'center',
            width: 150
        },
        {
            title: <FormattedMessage id="billingReceivablesCharge.year" />,
            dataIndex: 'year',
            key: 'year',
            align: 'center',
            width: 150
        },
        {
            title: <FormattedMessage id="billingReceivablesCharge.tax" />,
            dataIndex: 'tax',
            key: 'tax',
            align: 'left',
            render: (value: string, billingReceivablesCharge: BillingReceivablesCharge) => (
                <ConstantLabel value={billingReceivablesCharge?.tax} prefix="receivable.tax." />
            )
        },

        {
            title: <FormattedMessage id="billingReceivablesCharge.amount.principal" />,
            dataIndex: ['amount', 'principal'],
            key: 'amountPrincipal',
            align: 'right',
            width: 150,
            render: (value: number, billingReceivablesCharge: BillingReceivablesCharge) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        }
    ];

    const billingReceivablesCharges = charges?.billingReceivablesCharges ? charges.billingReceivablesCharges : [];
    return (
        <div className={styles.container}>
            <Tooltip title={<FormattedMessage id="button.download" />}>
                <Button size="large" icon={<FileExcelOutlined />} onClick={download} loading={loading === 'downloading'}>
                    <FormattedMessage id="button.download" tagName="span" />
                </Button>
            </Tooltip>
            <Table
                className={styles.table}
                dataSource={billingReceivablesCharges}
                columns={columns}
                pagination={false}
                rowKey="id"
                showSorterTooltip={false}
                loading={loading === 'loading'}
                summary={(pageData) => {
                    return (
                        <>
                            <Table.Summary.Row className={styles.summary}>
                                <Table.Summary.Cell index={0} align="center">
                                    <FormattedMessage id="billingReceivablesCharge.total" />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} />
                                <Table.Summary.Cell index={2} />
                                <Table.Summary.Cell index={3} align="center" />
                                <Table.Summary.Cell index={4} align="right">
                                    {charges && charges.amount && (
                                        <FormattedNumber
                                            value={charges.amount.principal}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </div>
    );
};
export default InvoicesChargesComponent;

interface Props {
    councilId: number;
    startDate: Dayjs;
    endDate: Dayjs;
}
