import { Page } from '../models/Elements';
import {
    Receivable,
    ReceivableFilter,
    ReceivableFull,
    ReceivableNotificationAbsent,
    ReceivableNotificationReceived,
    ReceivableNotificationSent,
    ReceivableNotificationUnknown,
    ReceivableNotificationWrongAddress
} from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class ReceivableApi {
    list = async (page: number, size: number, sortField: string, sortOrder: boolean, filter?: ReceivableFilter): Promise<Page<Receivable>> => {
        const response = await adminApiAxios.post<Page<Receivable>>(`/receivables/filters`, filter, {
            params: { page, size, sortField, sortOrder }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<Receivable> => {
        const response = await adminApiAxios.get<Receivable>(`/receivables/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    getFull = async (id: number): Promise<ReceivableFull> => {
        const response = await adminApiAxios.get<ReceivableFull>(`/receivables/${id}/full`);
        this.loadReceivableFullDates(response.data);

        return response.data;
    };

    create = async (receivable: Receivable): Promise<Receivable> => {
        const response = await adminApiAxios.post<Receivable>(`/receivables`, receivable);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (receivable: Receivable): Promise<Receivable> => {
        const response = await adminApiAxios.put<Receivable>(`/receivables/${receivable.id}`, receivable);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (id: number): Promise<void> => {
        const response = await adminApiAxios.delete<void>(`/receivables/${id}`);

        return response.data;
    };

    /**
     * Loads the dates of a receivable.
     * @param receivable - the receivable
     */
    private loadDates(receivable: Receivable) {
        receivable.audit!.created = dateService.transformDate(receivable.audit!.created)!;
        receivable.audit!.updated = dateService.transformDate(receivable.audit!.updated)!;
    }

    /**
     * Loads the dates of a receivable.
     * @param receivable - the receivable
     */
    private loadReceivableFullDates(receivableFull: ReceivableFull) {
        receivableFull.receivable.audit!.created = dateService.transformDate(receivableFull.receivable.audit!.created)!;
        receivableFull.receivable.audit!.updated = dateService.transformDate(receivableFull.receivable.audit!.updated)!;
        receivableFull.events.forEach((event) => {
            event.audit!.created = dateService.transformDate(event.audit!.created)!;
            event.audit!.updated = dateService.transformDate(event.audit!.updated)!;

            if (event.state === 'NOTIFICATION_SENT') {
                (event as ReceivableNotificationSent).sent = dateService.transformDate((event as ReceivableNotificationSent).sent)!;
            } else if (event.state === 'NOTIFICATION_ABSENT') {
                (event as ReceivableNotificationAbsent).paymentOrderEvent.received = dateService.transformDate(
                    (event as ReceivableNotificationAbsent).paymentOrderEvent.received
                )!;
            } else if (event.state === 'NOTIFICATION_UNKNOWN') {
                (event as ReceivableNotificationUnknown).paymentOrderEvent.received = dateService.transformDate(
                    (event as ReceivableNotificationUnknown).paymentOrderEvent.received
                )!;
            } else if (event.state === 'NOTIFICATION_WRONG_ADDRESS') {
                (event as ReceivableNotificationWrongAddress).paymentOrderEvent.received = dateService.transformDate(
                    (event as ReceivableNotificationWrongAddress).paymentOrderEvent.received
                )!;
            } else if (event.state === 'NOTIFICATION_RECEIVED') {
                (event as ReceivableNotificationReceived).paymentOrderEvent.received = dateService.transformDate(
                    (event as ReceivableNotificationReceived).paymentOrderEvent.received
                )!;
                (event as ReceivableNotificationReceived).paymentOrderEvent.expired = dateService.transformDate(
                    (event as ReceivableNotificationReceived).paymentOrderEvent.expired
                )!;
            }
        });
    }
}

const receivableApi: ReceivableApi = new ReceivableApi();
export default receivableApi;
