import { Button, Col, DatePicker, Form, Row, Tabs } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { TabsProps } from 'antd/lib';
import dayjs, { Dayjs } from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import alertService from '../../services/AlertService';
import styles from './CouncilInvoicesPage.module.scss';
import InvoicesChargesComponent from './InvoicesChargesComponent/InvoicesChargesComponent';
import InvoicesDataComponent from './InvoicesDataComponent/InvoicesDataComponent';

/**
 * Returns the council invoices page.
 * @returns the council invoices page.
 */

const CouncilInvoicesPage: React.FC = () => {
    /***HOOKS */
    const intl = useIntl();
    const [form] = useForm();
    const { council } = useContext(CustomCouncilContext);
    const [startDate, setStartDate] = useState<Dayjs>();
    const [endDate, setEndDate] = useState<Dayjs>();

    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    const startDate = dayjs().subtract(3, 'month');
                    const endDate = dayjs();
                    setStartDate(startDate);
                    setEndDate(endDate);
                    form.setFieldsValue({ startDate, endDate });
                }
            } catch (error) {
                alertService.displayError(error, intl);
            }
        };
        init();
    }, [intl, form, council]);

    /*** METHODS ***/

    const filter = (values: any) => {
        setStartDate(values.startDate);
        setEndDate(values.endDate);
    };

    /*** VISUAL ***/

    const tabs: TabsProps['items'] = [
        {
            key: 'charges',
            label: <FormattedMessage id="councilInvoices.charges" />,
            children: <InvoicesChargesComponent councilId={council.id!} startDate={startDate!} endDate={endDate!} />
        },
        {
            key: 'data',
            label: <FormattedMessage id="councilInvoices.data" />,
            children: <InvoicesDataComponent councilId={council.id!} startDate={startDate!} endDate={endDate!} />
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="councilBanks.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/invoices`, name: <FormattedMessage id="councilInvoices.title" /> }
            ]}
            council={council}
        >
            <Form form={form} onFinish={filter} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={8}>
                        <Form.Item
                            label={<FormattedMessage id="councilInvoices.startDate" />}
                            name="startDate"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <DatePicker className={styles.date} size="large" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={<FormattedMessage id="councilInvoices.endDate" />}
                            name="endDate"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <DatePicker className={styles.date} size="large" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button type="primary" size="large" htmlType="submit" className={styles.button}>
                            <FormattedMessage id="button.filter" />
                        </Button>
                    </Col>
                </Row>
            </Form>
            {council && council.id && <Tabs defaultActiveKey="receivables" type="card" className={styles.tabs} size="large" items={tabs} />}
        </LayoutComponent>
    );
};
export default CouncilInvoicesPage;
