import { Page } from '../models/Elements';
import { CensusDocument } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class CensusDocumentApi {
    list = async (page: number, size: number, sortField: string, sortOrder: boolean, councilId: number, searchText?: string): Promise<Page<CensusDocument>> => {
        const response = await adminApiAxios.get<Page<CensusDocument>>(`/census-documents`, {
            params: { page, size, sortField, sortOrder, councilId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<CensusDocument> => {
        const response = await adminApiAxios.get<CensusDocument>(`/census-documents/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (censusDocument: CensusDocument, file: any): Promise<CensusDocument> => {
        const formData: FormData = this.buildFormData(censusDocument, file);
        const response = await adminApiAxios.post<CensusDocument>(`/census-documents`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    update = async (censusDocument: CensusDocument): Promise<CensusDocument> => {
        const response = await adminApiAxios.put<CensusDocument>(`/census-documents/${censusDocument.id}`, censusDocument);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (id: number): Promise<void> => {
        const response = await adminApiAxios.delete<void>(`/census-documents/${id}`);

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/census-documents/${id}/documents`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Builds a form data from a census document.
     * @param censusDocument the census document
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (censusDocument: CensusDocument, file?: File) => {
        const formData: FormData = new FormData();
        censusDocument.id && formData.append('id', censusDocument.id.toString());
        censusDocument.councilId && formData.append('councilId', censusDocument.councilId.toString());
        censusDocument.tax && formData.append('tax', censusDocument.tax.toString());
        censusDocument.year && formData.append('year', censusDocument.year.toString());
        censusDocument.index && formData.append('index', censusDocument.index.toString());
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Loads the dates of a census document.
     * @param censusDocument - the census document
     */
    private loadDates(censusDocument: CensusDocument) {
        censusDocument.audit!.created = dateService.transformDate(censusDocument.audit!.created)!;
        censusDocument.audit!.updated = dateService.transformDate(censusDocument.audit!.updated)!;
    }
}

const censusDocumentApi: CensusDocumentApi = new CensusDocumentApi();
export default censusDocumentApi;
