import { Descriptions, Modal } from 'antd';
import { DescriptionsProps } from 'antd/lib';
import { FormattedDate, FormattedMessage } from 'react-intl';
import AuditComponent from '../../../../components/AuditComponent/AuditComponent';
import { TaxpayerVersion } from '../../../../models/Entities';
import styles from './TaxpayerVersionModal.module.scss';
import ProvinceComponent from '../../../../components/ProvinceComponent/ProvinceComponent';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';

const TaxpayerVersionModal = (props: Props) => {
    const { taxpayerVersion } = props;

    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    // cancel
    const cancel = async () => {
        props.onCancel();
    };

    /*** VISUAL ***/

    const items: DescriptionsProps['items'] = [
        {
            key: 'firstName',
            label: <FormattedMessage id="taxpayer.firstName" />,
            span: 2,
            children: taxpayerVersion.action.firstName
        },
        {
            key: 'lastName',
            label: <FormattedMessage id="taxpayer.lastName" />,
            span: 2,
            children: taxpayerVersion.action.lastName
        },
        {
            key: 'secondLastName',
            label: <FormattedMessage id="taxpayer.secondLastName" />,
            span: 2,
            children: taxpayerVersion.action.secondLastName
        },
        {
            key: 'identifier',
            label: <FormattedMessage id="taxpayer.identifier" />,
            span: 2,
            children: taxpayerVersion.action.identifier
        },
        {
            key: 'legalEntity',
            label: <FormattedMessage id="taxpayerVersion.legalEntity" />,
            span: 2,
            children: <ConstantLabel value={taxpayerVersion.action.legalEntity} prefix="taxpayer.legalEntity." />
        },
        {
            key: 'street',
            label: <FormattedMessage id="address.street" />,
            span: 2,
            children: taxpayerVersion.action.address.street
        },
        {
            key: 'postcode',
            label: <FormattedMessage id="address.postcode" />,
            span: 2,
            children: taxpayerVersion.action.address.postcode
        },
        {
            key: 'city',
            label: <FormattedMessage id="address.city" />,
            span: 2,
            children: taxpayerVersion.action.address.city
        },
        {
            key: 'province',
            label: <FormattedMessage id="address.province" />,
            span: 2,
            children: <ProvinceComponent provinceId={taxpayerVersion.action.address.province} />
        },
        {
            key: 'email',
            label: <FormattedMessage id="address.email" />,
            span: 2,
            children: taxpayerVersion.action.address.email
        },
        {
            key: 'phone',
            label: <FormattedMessage id="address.phone" />,
            span: 2,
            children: taxpayerVersion.action.address.phone
        },
        {
            key: 'notification',
            label: <FormattedMessage id="taxpayer.notification" />,
            span: 2,
            children: <ConstantLabel value={taxpayerVersion.action.notification} prefix="taxpayer.notificationType." />
        },
        {
            key: 'notificationDate',
            label: <FormattedMessage id="taxpayer.notificationDate" />,
            span: 2,
            children: taxpayerVersion.action.notificationDate && (
                <FormattedDate
                    value={taxpayerVersion.action.notificationDate as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                />
            )
        }
    ];

    return (
        <>
            <Modal
                title={
                    <>
                        <FormattedMessage id="taxpayerVersion.action.modal.title" />
                        <FormattedDate
                            value={taxpayerVersion.audit!.created! as any}
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                            hour="2-digit"
                            minute="2-digit"
                        />
                    </>
                }
                open={true}
                onOk={cancel}
                onCancel={cancel}
                okText={<FormattedMessage id="button.close" tagName="span" />}
                width={1000}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Descriptions layout="horizontal" items={items} column={2} bordered className={styles.descriptions} />
                <AuditComponent entity={taxpayerVersion} />
            </Modal>
        </>
    );
};
export default TaxpayerVersionModal;

interface Props {
    onCancel: () => void;
    taxpayerVersion: TaxpayerVersion;
}
