import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import SignInPage from '../../pages/AuthsPage/SignInPage/SignInPage';
import SignUpPage from '../../pages/AuthsPage/SignUpPage/SignUpPage';
import BankPage from '../../pages/BanksPage/BankPage/BankPage';
import BanksPage from '../../pages/BanksPage/BanksPage';
import CouncilBankPage from '../../pages/CouncilBanksPage/CouncilBankPage/CouncilBankPage';
import CouncilBanksPage from '../../pages/CouncilBanksPage/CouncilBanksPage';
import CouncilCertificatePage from '../../pages/CouncilCertificatePage/CouncilCertificatePage';
import CouncilTaxpayersPage from '../../pages/CouncilTaxpayersPage/CouncilTaxpayersPage';
import CouncilUserPage from '../../pages/CouncilUsersPage/CouncilUserPage/CouncilUserPage';
import CouncilUsersPage from '../../pages/CouncilUsersPage/CouncilUsersPage';
import CouncilPage from '../../pages/CouncilsPage/CouncilPage/CouncilPage';
import CouncilsPage from '../../pages/CouncilsPage/CouncilsPage';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import ForgotPasswordPage from '../../pages/ForgotPasswordPage/ForgotPasswordPage';
import NationalParameterPage from '../../pages/NationalParametersPage/NationalParameterPage/NationalParameterPage';
import NationalParametersPage from '../../pages/NationalParametersPage/NationalParametersPage';
import ReceivablesImportPage from '../../pages/ReceivablesImportPage/ReceivablesImportPage';
import SettingsPage from '../../pages/SettingsPage/SettingsPage';
import LegalNoticePage from '../../pages/Static/LegalNoticePage/LegalNoticePage';
import PrivacyPolicyPage from '../../pages/Static/PrivacyPolicyPage/PrivacyPolicyPage';
import TaxpayerPage from '../../pages/TaxpayersPage/TaxpayerPage/TaxpayerPage';
import TaxpayerVersionsPage from '../../pages/TaxpayersPage/TaxpayerPage/TaxpayerVersionsPage/TaxpayerVersionsPage';
import UserPage from '../../pages/UsersPage/UserPage/UserPage';
import CouncilWrapper from '../CouncilWrapper/CouncilWrapper';
import DefaultRoute from '../DefaultRoute/DefaultRoute';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import TaxpayerWrapper from '../TaxpayerWrapper/TaxpayerWrapper';
import CouncilTaxpayerWrapper from '../CouncilTaxpayerWrapper/CouncilTaxpayerWrapper';
import CouncilTaxpayerPage from '../../pages/CouncilTaxpayersPage/CouncilTaxpayerPage/CouncilTaxpayerPage';
import PaymentOrderSignsPage from '../../pages/PaymentOrderSignsPage/PaymentOrderSignsPage';
import PaymentOrderSignPage from '../../pages/PaymentOrderSignsPage/PaymentOrderSignPage/PaymentOrderSignPage';
import PaymentOrdersPage from '../../pages/PaymentOrdersPage/PaymentOrdersPage';
import ReceivablePage from '../../pages/ReceivablesPage/ReceivablePage/ReceivablePage';
import UsersPage from '../../pages/UsersPage/UsersPage';
import TaxpayersPage from '../../pages/TaxpayersPage/TaxpayersPage';
import ReceivablesPage from '../../pages/ReceivablesPage/ReceivablesPage';
import AnnouncementsPage from '../../pages/AnnouncementsPage/AnnouncementsPage';
import AnnouncementPage from '../../pages/AnnouncementSignsPage/AnnouncementSignPage/AnnouncementPage';
import SeizureIncomesPage from '../../pages/SeizureIncomesPage/SeizureIncomesPage';
import SeizureIncomeSignsPage from '../../pages/SeizureIncomeSignsPage/SeizureIncomeSignsPage';
import SeizureIncomeSignPage from '../../pages/SeizureIncomeSignsPage/SeizureIncomeSignPage/SeizureIncomeSignPage';
import SeizureIncomeOrdersPage from '../../pages/SeizureIncomeOrdersPage/SeizureIncomeOrdersPage';
import CouncilCollectionAccountsPage from '../../pages/CouncilCollectionAccountsPage/CouncilCollectionAccountsPage';
import CouncilCollectionAccountPage from '../../pages/CouncilCollectionAccountsPage/CouncilCollectionAccountPage/CouncilCollectionAccountPage';
import CouncilBillingPage from '../../pages/CouncilBillingPage/CouncilBillingPage';
import PaymentOrderUnofficialsPage from '../../pages/PaymentOrderUnofficialsPage/PaymentOrderUnofficialsPage';
import SeizureBankInfoRequestPage from '../../pages/SeizureBankInfoRequestPage/SeizureBankInfoRequestPage';
import SeizureBankInfoResponsePage from '../../pages/SeizureBankInfoResponsePage/SeizureBankInfoResponsePage';
import SeizureBankAmountRequestPage from '../../pages/SeizureBankAmountRequestPage/SeizureBankAmountRequestPage';
import SeizureBankAmountResponsePage from '../../pages/SeizureBankAmountResponsePage/SeizureBankAmountResponsePage';
import PaymentLettersPage from '../../pages/PaymentLettersPage/PaymentLettersPage';
import CensusUrbanPropertiesPage from '../../pages/CensusUrbanPropertiesPage/CensusUrbanPropertiesPage';
import CensusDocumentsImportPage from '../../pages/CensusDocumentsImportPage/CensusDocumentsImportPage';
import CensusUrbanPropertyPage from '../../pages/CensusUrbanPropertiesPage/CensusUrbanPropertyPage/CensusUrbanPropertyPage';
import VoluntaryReceivablesPage from '../../pages/VoluntaryReceivablesPage/VoluntaryReceivablesPage';
import CouncilStatisticsPage from '../../pages/CouncilStatisticsPage/CouncilStatisticsPage';
import CouncilInvoicesPage from '../../pages/CouncilInvoicesPage/CouncilInvoicesPage';

const RoutesComponent: React.FC = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoute authorities={['ROLE_COUNCIL_USER']} redirectPath="/signin" />}>
                <Route path="/" element={<DefaultRoute />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/councils/:councilId" element={<CouncilWrapper />}>
                    <Route index element={<CouncilPage />} />
                    <Route path="payment-order-signs" element={<PaymentOrderSignsPage />} />
                    <Route path="payment-order-signs/:id" element={<PaymentOrderSignPage />} />
                    <Route path="announcements" element={<AnnouncementsPage />} />
                    <Route path="announcements/:id" element={<AnnouncementPage />} />
                    <Route path="seizure-bank-info-request" element={<SeizureBankInfoRequestPage />} />
                    <Route path="seizure-bank-info-response" element={<SeizureBankInfoResponsePage />} />
                    <Route path="seizure-bank-amount-request" element={<SeizureBankAmountRequestPage />} />
                    <Route path="seizure-bank-amount-response" element={<SeizureBankAmountResponsePage />} />
                    <Route path="seizure-incomes" element={<SeizureIncomesPage />} />
                    <Route path="seizure-income-signs" element={<SeizureIncomeSignsPage />} />
                    <Route path="seizure-income-signs/:id" element={<SeizureIncomeSignPage />} />
                    <Route path="seizure-income-orders" element={<SeizureIncomeOrdersPage />} />
                    <Route element={<ProtectedRoute authorities={['ROLE_COUNCIL_ADMIN']} redirectPath="/" />}>
                        <Route path="users" element={<CouncilUsersPage />} />
                        <Route path="users/:id" element={<CouncilUserPage />} />
                    </Route>
                </Route>
            </Route>

            <Route element={<ProtectedRoute authorities={['ROLE_ADMIN']} />}>
                <Route path="/banks" element={<BanksPage />} />
                <Route path="/banks/:id" element={<BankPage />} />
                <Route path="/users" element={<UsersPage />} />
                <Route path="/users/:id" element={<UserPage />} />
                <Route path="/national-parameters" element={<NationalParametersPage />} />
                <Route path="/national-parameters/:id" element={<NationalParameterPage />} />
                <Route path="/taxpayers" element={<TaxpayersPage />} />
                <Route path="/taxpayers/:taxpayerId" element={<TaxpayerWrapper />}>
                    <Route index element={<TaxpayerPage />} />
                    <Route path="history" element={<TaxpayerVersionsPage />} />
                </Route>
                <Route path="/councils" element={<CouncilsPage />} />
                <Route path="/councils/:councilId" element={<CouncilWrapper />}>
                    <Route index element={<CouncilPage />} />
                    <Route path="payment-orders" element={<PaymentOrdersPage />} />
                    <Route path="payment-order-unofficials" element={<PaymentOrderUnofficialsPage />} />
                    <Route path="receivables" element={<ReceivablesPage />} />
                    <Route path="receivables/:id" element={<ReceivablePage />} />
                    <Route path="receivables/import" element={<ReceivablesImportPage />} />
                    <Route path="voluntary-receivables" element={<VoluntaryReceivablesPage />} />
                    <Route path="census-urban-properties" element={<CensusUrbanPropertiesPage />} />
                    <Route path="census-urban-properties/:id" element={<CensusUrbanPropertyPage />} />
                    <Route path="census-urban-properties/import" element={<CensusDocumentsImportPage tax="IMPUESTO_BIENES_INMUEBLES_DE_NATURALEZA_URBANA" />} />
                    <Route path="taxpayers" element={<CouncilTaxpayersPage />} />
                    <Route path="taxpayers/:councilTaxpayerId" element={<CouncilTaxpayerWrapper />}>
                        <Route index element={<CouncilTaxpayerPage />} />
                        <Route path="receivables/:id" element={<ReceivablePage />} />
                    </Route>
                    <Route path="banks" element={<CouncilBanksPage />} />
                    <Route path="banks/:id" element={<CouncilBankPage />} />
                    <Route path="certificates" element={<CouncilCertificatePage />} />
                    <Route path="users" element={<CouncilUsersPage />} />
                    <Route path="users/:id" element={<CouncilUserPage />} />
                    <Route path="collection-accounts" element={<CouncilCollectionAccountsPage />} />
                    <Route path="collection-accounts/:id" element={<CouncilCollectionAccountPage />} />
                    <Route path="billing" element={<CouncilBillingPage />} />
                    <Route path="statistics" element={<CouncilStatisticsPage />} />
                    <Route path="invoices" element={<CouncilInvoicesPage />} />
                    <Route path="payment-letters" element={<PaymentLettersPage />} />
                </Route>
            </Route>

            <Route path="/signin" element={<SignInPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/legal-notice" element={<LegalNoticePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    );
};
export default RoutesComponent;
