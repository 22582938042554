import { Checkbox, Col, Empty, Form, List, Row, Select, Space } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import councilTaxpayerSeizureIncomeApi from '../../../../apis/CouncilTaxpayerSeizureIncomeApi';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';
import CustomCouncilTaxpayerContext from '../../../../contexts/CustomCouncilTaxpayerContext';
import { CouncilTaxpayerSeizureIncome } from '../../../../models/Entities';
import { councilTaxpayerSeizureIncomePhaseTypes } from '../../../../models/Types';
import alertService from '../../../../services/AlertService';
import CouncilTaxpayerSeizureIncomeComponent from '../CouncilTaxpayerSeizureIncomeComponent/CouncilTaxpayerSeizureIncomeComponent';
import styles from './CouncilTaxpayerSeizureIncomesComponent.module.scss';

/**
 * The council taxpayer seizure incomes component with the salaries/pensions information.
 * @param props the props
 * @returns the council taxpayer seizure incomes component
 */
const CouncilTaxpayerSeizureIncomesComponent: React.FC = () => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const { councilTaxpayer } = useContext(CustomCouncilTaxpayerContext);
    const [councilTaxpayerSeizureIncomes, setCouncilTaxpayerSeizureIncomes] = useState<CouncilTaxpayerSeizureIncome[]>();
    const [loading, setLoading] = useState<'loading' | 'refreshing'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            if (councilTaxpayer) {
                try {
                    setLoading('loading');
                    const councilTaxpayerSeizureIncomePage = await councilTaxpayerSeizureIncomeApi.list(0, 1000, 'id', true, councilTaxpayer.id!);
                    setCouncilTaxpayerSeizureIncomes(councilTaxpayerSeizureIncomePage.content);
                } catch (error) {
                    alertService.displayError(error, intl);
                } finally {
                    setLoading(undefined);
                }
            }
        };
        init();
    }, [councilTaxpayer, intl]);

    /*** METHODS ***/

    const refresh = async () => {
        if (councilTaxpayer) {
            try {
                setLoading('loading');
                const includeCancelled = form.getFieldValue('includeCancelled');
                const phase = form.getFieldValue('phase');
                const councilTaxpayerSeizureIncomePage = await councilTaxpayerSeizureIncomeApi.list(
                    0,
                    1000,
                    'id',
                    true,
                    councilTaxpayer.id!,
                    includeCancelled,
                    phase
                );
                setCouncilTaxpayerSeizureIncomes(councilTaxpayerSeizureIncomePage.content);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        }
    };

    /*** VISUAL ***/

    const phaseOptions = councilTaxpayerSeizureIncomePhaseTypes.map((phase) => ({
        value: phase,
        label: <ConstantLabel value={phase} prefix="councilTaxpayerSeizureIncome." />
    }));

    return (
        <>
            <Form form={form} onFinish={refresh} colon={false} layout="vertical" requiredMark={false} className={styles.form}>
                <Row gutter={[0, 0]}>
                    <Col span={24}>
                        <Space className={styles.filter}>
                            <Form.Item name="phase">
                                <Select
                                    placeholder={intl.formatMessage({
                                        id: 'seizureBankAccount.filter'
                                    })}
                                    size="large"
                                    className={styles.phase}
                                    allowClear
                                    maxTagCount={'responsive'}
                                    onChange={form.submit}
                                    options={[...phaseOptions]}
                                />
                            </Form.Item>
                            <Form.Item name="includeCancelled" valuePropName="checked">
                                <Checkbox onChange={form.submit} className={styles.checkbox}>
                                    <FormattedMessage id="councilTaxpayerSeizureIncome.include.CANCELLED" />
                                </Checkbox>
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Col span={24}>
                <List
                    itemLayout="vertical"
                    loading={loading === 'loading'}
                    dataSource={councilTaxpayerSeizureIncomes}
                    locale={{ emptyText: <Empty /> }}
                    split={false}
                    renderItem={(councilTaxpayerSeizureIncome) => (
                        <List.Item>
                            <CouncilTaxpayerSeizureIncomeComponent councilTaxpayerSeizureIncome={councilTaxpayerSeizureIncome} onUpdate={refresh} />
                        </List.Item>
                    )}
                />
            </Col>
        </>
    );
};
export default CouncilTaxpayerSeizureIncomesComponent;
