import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Divider, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Dayjs } from 'dayjs';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import billingApi from '../../../../apis/BillingApi';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';
import { BillingReceivableCancellationsByFiscalYearCreatedYearTax, BillingReceivableCancellationsFiscalYearCreatedYearTax } from '../../../../models/Entities';
import { ProcessType } from '../../../../models/Types';
import alertService from '../../../../services/AlertService';
import styles from './CancellationsTableComponent.module.scss';

/**
 *
 * @param props the props
 * @returns the cancellations table component
 */
const CancellationsTableComponent: React.FC<Props> = (props) => {
    const { councilId, startDate, endDate, process } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading' | 'loading'>();
    const [cancellations, setCancellations] = useState<BillingReceivableCancellationsByFiscalYearCreatedYearTax>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const cancellations = await billingApi.getCancellationsByFiscalYearCreatedTax(councilId, startDate, endDate, process);
                setCancellations(cancellations);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, endDate, intl, process, startDate]);

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');

            const document = await billingApi.downloadCancellationsByFiscalYearCreatedTax(councilId, startDate, endDate, process);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'invoiceCancellationsFiscalYearCreatedYearTax.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const billingReceivableCancellationsFiscalYearCreatedYearTaxes = cancellations?.billingReceivableCancellationsFiscalYearCreatedYearTaxes
        ? cancellations?.billingReceivableCancellationsFiscalYearCreatedYearTaxes
        : [];

    const cancellationsColumns: ColumnsType<BillingReceivableCancellationsFiscalYearCreatedYearTax> = [
        {
            title: <FormattedMessage id="billingReceivableCancellationsFiscalYearCreatedYearTax.tax" />,
            dataIndex: 'tax',
            key: 'tax',
            align: 'left',
            render: (value: string, billingReceivableCancellationsFiscalYearCreatedYearTax: BillingReceivableCancellationsFiscalYearCreatedYearTax) => (
                <ConstantLabel value={value} prefix="receivable.tax." />
            )
        },
        {
            title: <FormattedMessage id="billingReceivableCancellationsFiscalYearCreatedYearTax.fiscalYear" />,
            dataIndex: 'fiscalYear',
            key: 'fiscalYear',
            align: 'center',
            width: 100
        },
        {
            title: <FormattedMessage id="billingReceivableCancellationsFiscalYearCreatedYearTax.createdYear" />,
            dataIndex: 'createdYear',
            key: 'createdYear',
            align: 'center',
            width: 100
        },

        {
            title: <FormattedMessage id="billingReceivableCancellationsFiscalYearCreatedYearTax.amount.principal" />,
            dataIndex: ['amount', 'principal'],
            key: 'amountPrincipal',
            align: 'right',
            width: 100,
            render: (value: number, billingReceivableCancellationsFiscalYearCreatedYearTax: BillingReceivableCancellationsFiscalYearCreatedYearTax) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        }
    ];

    return (
        <>
            <Divider orientation="left" className={styles.divider}>
                {process === 'VOLUNTARY' ? (
                    <FormattedMessage id="invoicesDataComponent.voluntaryCancellationsTable" />
                ) : (
                    <FormattedMessage id="invoicesDataComponent.executiveCancellationsTable" />
                )}
            </Divider>
            <Tooltip title={<FormattedMessage id="button.download" />}>
                <Button size="large" icon={<FileExcelOutlined />} onClick={download} loading={loading === 'downloading'}>
                    <FormattedMessage id="button.download" tagName="span" />
                </Button>
            </Tooltip>
            <Table
                className={styles.table}
                dataSource={billingReceivableCancellationsFiscalYearCreatedYearTaxes}
                columns={cancellationsColumns}
                pagination={false}
                rowKey="id"
                showSorterTooltip={false}
                loading={loading === 'loading'}
                summary={(pageData) => {
                    return (
                        <>
                            <Table.Summary.Row className={styles.summary}>
                                <Table.Summary.Cell index={0} align="left">
                                    <FormattedMessage id="billingPaymentsFiscalYearCreatedYearTax.total" />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} />
                                <Table.Summary.Cell index={2} />
                                <Table.Summary.Cell index={3} align="right">
                                    {cancellations && cancellations.amount && (
                                        <FormattedNumber
                                            value={cancellations.amount.principal}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </>
    );
};
export default CancellationsTableComponent;

interface Props {
    councilId: number;
    startDate: Dayjs;
    endDate: Dayjs;
    process: ProcessType;
}
