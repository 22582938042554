import { Page } from '../models/Elements';
import { PaymentOrderEvent } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class PaymentOrderEventApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        paymentOrderId?: number,
        searchText?: string
    ): Promise<Page<PaymentOrderEvent>> => {
        const response = await adminApiAxios.get<Page<PaymentOrderEvent>>('/payment-order-events', {
            params: { page, size, sortField, sortOrder, paymentOrderId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<PaymentOrderEvent> => {
        const response = await adminApiAxios.get<PaymentOrderEvent>(`/payment-order-events/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (paymentOrderEvent: PaymentOrderEvent, file: any): Promise<PaymentOrderEvent> => {
        const formData: FormData = this.buildFormData(paymentOrderEvent, file);
        const response = await adminApiAxios.post<PaymentOrderEvent>('/payment-order-events', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/payment-order-events/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Builds a form data.
     * @param paymentOrderEvent, file
     * @returns the form data
     */
    private buildFormData = (paymentOrderEvent: PaymentOrderEvent, file?: File) => {
        const formData: FormData = new FormData();
        paymentOrderEvent.id && formData.append('id', paymentOrderEvent.id.toString());
        paymentOrderEvent.paymentOrderId && formData.append('paymentOrderId', paymentOrderEvent.paymentOrderId.toString());
        paymentOrderEvent.received && formData.append('received', paymentOrderEvent.received.format('YYYY-MM-DDThh:mm:ss'));
        paymentOrderEvent.expired && formData.append('expired', paymentOrderEvent.expired.format('YYYY-MM-DDThh:mm:ss'));
        paymentOrderEvent.remarks && formData.append('remarks', paymentOrderEvent.remarks.toString());
        paymentOrderEvent.state && formData.append('state', paymentOrderEvent.state.toString());
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Loads the dates of a paymentOrderEvent.
     * @param paymentOrderEvent - the payment order event
     */
    private loadDates(paymentOrderEvent: PaymentOrderEvent) {
        paymentOrderEvent.audit!.created = dateService.transformDate(paymentOrderEvent.audit!.created)!;
        paymentOrderEvent.audit!.updated = dateService.transformDate(paymentOrderEvent.audit!.updated)!;
        paymentOrderEvent.received = dateService.transformDate(paymentOrderEvent.received)!;
        paymentOrderEvent.expired = dateService.transformDate(paymentOrderEvent.expired)!;
    }
}

const paymentOrderEventApi: PaymentOrderEventApi = new PaymentOrderEventApi();
export default paymentOrderEventApi;
