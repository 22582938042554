import { ArrowLeftOutlined, CloudDownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import FileSaver from 'file-saver';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import paymentOrderUnofficialApi from '../../apis/PaymentOrderUnofficialApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import PaymentOrderUnofficialModal from '../../components/PaymentOrderUnofficialModal/PaymentOrderUnofficialModal';
import ReceptionStateTag from '../../components/ReceptionStateTag/ReceptionStateTag';
import CustomContext from '../../contexts/CustomContext';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { Page } from '../../models/Elements';
import { CustomAuth, PaymentOrderUnofficial, Receivable } from '../../models/Entities';
import { ReceptionStateType } from '../../models/Types';
import alertService from '../../services/AlertService';
import rolesService from '../../services/RolesService';
import tableService from '../../services/TableService';
import styles from './PaymentOrderUnofficialsPage.module.scss';

/**
 * Returns the payment order unofficials page.
 * @returns the payment order unofficials page.
 */

const PaymentOrderUnofficialsPage: React.FC = () => {
    /***HOOKS */

    const intl = useIntl();
    const [form] = Form.useForm();
    const { council } = useContext(CustomCouncilContext);
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const [desktop] = useResponsiveLayout();
    const [filter, setFilter] = useState<Filter>({});
    const [paymentOrderUnofficialsPage, setPaymentOrderUnofficialsPage] = useState<Page<PaymentOrderUnofficial>>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('auditCreated');
    const [sortOrder, setSortOrder] = useState<boolean>(false);

    const [paymentOrderUnofficialModalVisible, setPaymentOrderUnofficialModalVisible] = useState<boolean>(false);

    const [loading, setLoading] = useState<'loading' | 'downloading'>();

    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('loading');
                    const paymentOrderUnofficialsPage = await paymentOrderUnofficialApi.list(
                        page,
                        50,
                        sortField,
                        sortOrder,
                        council.id,
                        undefined,
                        filter.searchText
                    );
                    setPaymentOrderUnofficialsPage(paymentOrderUnofficialsPage);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter, council]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterPaymentOrderUnofficials = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    const download = async (paymentOrderUnofficial: PaymentOrderUnofficial) => {
        try {
            setLoading('downloading');
            const document = await paymentOrderUnofficialApi.download(paymentOrderUnofficial.id);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'paymentOrderUnofficial.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const save = async () => {
        try {
            setLoading('loading');

            setPaymentOrderUnofficialModalVisible(false);
            const paymentOrderUnofficialsPage = await paymentOrderUnofficialApi.list(page, 100, sortField, sortOrder, council.id, undefined, filter.searchText);
            setPaymentOrderUnofficialsPage(paymentOrderUnofficialsPage);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/
    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);
    const items = paymentOrderUnofficialsPage ? paymentOrderUnofficialsPage.content : [];
    const columns: ColumnsType<PaymentOrderUnofficial> = [
        {
            title: <FormattedMessage id="paymentOrderUnofficial.taxpayer.name" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'fullName'],
            key: 'fullName'
        },
        {
            title: <FormattedMessage id="paymentOrderUnofficial.receivables" />,
            dataIndex: 'receivables',
            key: 'receivables',
            width: 180,
            render: (receivables: Receivable[]) => receivables.map((r) => r.reference).join(', ')
        },
        {
            title: <FormattedMessage id="paymentOrderUnofficial.taxpayer.street" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'address', 'street'],
            key: 'street',
            width: 220
        },
        {
            title: <FormattedMessage id="paymentOrderUnofficial.taxpayer.city" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'address', 'city'],
            key: 'city',
            width: 160
        },
        {
            title: <FormattedMessage id="paymentOrderUnofficial.taxpayer.identifier" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'identifier'],
            key: 'identifier',
            align: 'center',
            width: 80
        },

        {
            title: <FormattedMessage id="paymentOrderUnofficial.created" />,
            dataIndex: ['audit', 'created'],
            key: 'auditCreated',
            align: 'center',
            width: 140,
            render: (value: any, paymentOrder: PaymentOrderUnofficial) => <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" />
        },
        {
            title: <FormattedMessage id="paymentOrderUnofficial.receptionState" />,
            dataIndex: 'receptionState',
            key: 'receptionState',
            align: 'center',
            width: 120,
            render: (value: ReceptionStateType | undefined, paymentOrder: PaymentOrderUnofficial) => <ReceptionStateTag value={value} />
        },
        {
            key: 'actions',
            width: 140,
            align: 'center',
            render: (paymentOrderUnofficial: PaymentOrderUnofficial) => (
                <Space size="small">
                    <Tooltip title={<FormattedMessage id="button.download" />}>
                        <Button icon={<CloudDownloadOutlined />} className={styles.icon} onClick={() => download(paymentOrderUnofficial)} type="link" />
                    </Tooltip>
                </Space>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="users.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/payment-order-unofficials`, name: <FormattedMessage id="paymentOrderUnofficials.title" /> }
            ]}
            council={council}
        >
            <Form form={form} onFinish={filterPaymentOrderUnofficials} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={18}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'paymentOrderUnofficials.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6} className={styles.buttons}>
                        <Space>
                            <Link to={`/councils/${council?.id}`}>
                                <Button size="large" icon={<ArrowLeftOutlined />} />
                            </Link>
                            {isAdmin && (
                                <Tooltip title={<FormattedMessage id="paymentOrderUnofficials.new.tooltip" />}>
                                    <Button type="primary" size="large" icon={<PlusOutlined />} onClick={() => setPaymentOrderUnofficialModalVisible(true)}>
                                        {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                    </Button>
                                </Tooltip>
                            )}
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(paymentOrderUnofficialsPage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />

            {paymentOrderUnofficialModalVisible && (
                <PaymentOrderUnofficialModal
                    councilId={council.id!}
                    clone={false}
                    onCancel={() => setPaymentOrderUnofficialModalVisible(false)}
                    onSave={save}
                />
            )}
        </LayoutComponent>
    );
};
export default PaymentOrderUnofficialsPage;

interface Filter {
    searchText?: string | undefined;
}
