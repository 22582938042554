import { CaretDownFilled, MailOutlined, WalletOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomContext from '../../contexts/CustomContext';
import { Council, Receivable } from '../../models/Entities';
import rolesService from '../../services/RolesService';
import VoluntaryReceivablesSendModal from '../VoluntaryReceivablesSendModal/VoluntaryReceivablesSendModal';
import styles from './ReceivableActionsMenu.module.scss';
import VoluntaryPaymentModal from '../VoluntaryPaymentModal/VoluntaryPaymentModal';

const ReceivableActionsMenu = (props: Props) => {
    const { receivables, council, onAction } = props;

    /*** HOOKS ***/

    const { auth } = useContext(CustomContext);
    const [voluntaryReceivablesSendModalVisible, setVoluntaryReceivablesSendModalVisible] = useState<boolean>(false);
    const [voluntaryPaymentModalVisible, setVoluntaryPaymentModalVisible] = useState<boolean>(false);

    /*** METHODS ***/

    const save = async () => {
        setVoluntaryReceivablesSendModalVisible(false);

        onAction();
    };

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);

    const items: ItemType[] = [
        {
            key: 'send',
            type: 'group',
            label: (
                <span className={styles.label}>
                    <FormattedMessage id="receivablesVoluntary.actions.header.send" />
                </span>
            ),
            children: [
                {
                    key: 'sendVoluntaryReceivables',
                    icon: <MailOutlined />,
                    onClick: () => setVoluntaryReceivablesSendModalVisible(true),
                    label: <FormattedMessage id="receivablesVoluntary.actions.sendReceivables" />,
                    disabled: !isAdmin
                }
            ]
        },
        {
            key: 'pay',
            type: 'group',
            label: (
                <span className={styles.label}>
                    <FormattedMessage id="receivablesVoluntary.actions.header.pay" />
                </span>
            ),
            children: [
                {
                    key: 'payVoluntaryReceivables',
                    icon: <WalletOutlined />,
                    onClick: () => setVoluntaryPaymentModalVisible(true),
                    label: <FormattedMessage id="receivablesVoluntary.actions.payReceivables" />,
                    disabled: true
                }
            ]
        }
    ];

    return (
        <>
            <Dropdown trigger={['click']} placement="bottomRight" menu={{ items }}>
                <Button type="primary" size="large">
                    <FormattedMessage id="button.actions" /> <CaretDownFilled />
                </Button>
            </Dropdown>

            {voluntaryReceivablesSendModalVisible && council && council.id && (
                <VoluntaryReceivablesSendModal councilId={council.id} onCancel={() => setVoluntaryReceivablesSendModalVisible(false)} onSave={save} />
            )}
            {voluntaryPaymentModalVisible && council && council.id && (
                <VoluntaryPaymentModal receivables={receivables} councilId={council.id} onCancel={() => setVoluntaryPaymentModalVisible(false)} onSave={save} />
            )}
        </>
    );
};

export default ReceivableActionsMenu;

interface Props {
    receivables: Receivable[];
    council?: Council;
    onAction: () => Promise<void>;
}
