import { ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { App, Button, Col, Form, Input, Row, Select, message } from 'antd';
import { Space } from 'antd/lib';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import councilUserApi from '../../../apis/CouncilUserApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomContext from '../../../contexts/CustomContext';
import CustomCouncilContext from '../../../contexts/CustomCouncilContext';
import { User } from '../../../models/Entities';
import { positionTypes, userTypes } from '../../../models/Types';
import alertService from '../../../services/AlertService';
import rolesService from '../../../services/RolesService';

/**
 * Returns the council user page.
 * @returns the council user page.
 */
const CouncilUserPage: React.FC = () => {
    /***HOOKS***/
    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { auth } = useContext(CustomContext);
    const { council } = useContext(CustomCouncilContext);
    const [loading, setLoading] = useState<'initializing' | 'loading' | 'deleting'>();
    const [user, setUser] = useState<User>();
    const params = useParams<ParamsType>();
    const { modal } = App.useApp();

    /*** EFFECTS ***/
    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('initializing');
                    let user: User;
                    if (params.id === 'new') {
                        user = { councilId: council.id, type: 'COUNCIL_USER', status: 'ENABLED' };
                    } else {
                        user = await councilUserApi.get(council.id, +params.id!);
                    }
                    form.setFieldsValue(user);
                    setUser(user);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [council, form, intl, params.id]);

    /*** METHODS ***/
    const save = async (values: any) => {
        try {
            setLoading('loading');

            let updatedUser: User = Object.assign({}, user, values);
            if (updatedUser.id) {
                updatedUser = await councilUserApi.update(updatedUser);
            } else {
                updatedUser = await councilUserApi.create(updatedUser);
            }
            setUser(updatedUser);

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error) {
            alertService.displayError(error, intl, [{ status: 409, message: 'user.email.duplicate' }]);
        } finally {
            setLoading(undefined);
        }
    };

    const remove = async () => {
        modal.confirm({
            title: intl.formatMessage({ id: 'user.deleteModal.title' }),
            okButtonProps: { loading: loading === 'deleting' },
            onOk: async () => {
                try {
                    if (user && user.councilId && user.id) {
                        setLoading('deleting');
                        await councilUserApi.delete(user.councilId, user.id);
                        message.success(intl.formatMessage({ id: 'status.deleted' }));
                        navigate(`/councils/${user?.councilId}/users`);
                    }
                } catch (error) {
                    alertService.displayError(error, intl);
                } finally {
                    setLoading(undefined);
                }
            }
        });
    };

    /*** VISUAL ***/

    const isCouncilAdmin = rolesService.hasAnyRole(auth, ['ROLE_COUNCIL_ADMIN']);
    const userTypeOptions = userTypes.filter((u) => u !== 'ADMIN').map((t) => ({ value: t, label: <FormattedMessage id={`user.type.${t}`} /> }));
    const positionTypeOptions = positionTypes.map((t) => ({ value: t, label: <FormattedMessage id={`user.positions.${t}`} /> }));

    return (
        <LayoutComponent
            title={<FormattedMessage id="users.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/users`, name: <FormattedMessage id="users.title" /> },
                {
                    path: `/councils/${council?.id}/users/${user?.id}`,
                    name: user && user.id && `${user?.lastName}, ${user?.firstName}`
                }
            ]}
            council={council}
        >
            <Form form={form} onFinish={save} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col span={12} lg={12}>
                        <Form.Item
                            label={<FormattedMessage id="account.email" />}
                            name="email"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    type: 'email',
                                    message: <FormattedMessage id="status.email.invalid" />
                                }
                            ]}
                        >
                            <Input size="large" maxLength={200} disabled={!isCouncilAdmin} />
                        </Form.Item>
                    </Col>
                    <Col span={12} lg={12}>
                        <Form.Item
                            label={<FormattedMessage id="account.type" />}
                            name="type"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select size="large" disabled={!isCouncilAdmin} options={[...userTypeOptions]} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={12} lg={6}>
                        <Form.Item
                            label={<FormattedMessage id="account.firstName" />}
                            name="firstName"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={100} disabled={!isCouncilAdmin} />
                        </Form.Item>
                    </Col>
                    <Col span={12} lg={6}>
                        <Form.Item
                            label={<FormattedMessage id="account.lastName" />}
                            name="lastName"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={100} disabled={!isCouncilAdmin} />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={12}>
                        <Form.Item
                            label={<FormattedMessage id="user.positions" />}
                            name="positions"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select size="large" disabled={!isCouncilAdmin} mode="multiple" allowClear options={[...positionTypeOptions]} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]} className="buttons">
                    <Col span={16}>
                        <Space>
                            {isCouncilAdmin && (
                                <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                                    <FormattedMessage id="button.save" tagName="span" />
                                </Button>
                            )}
                            {user && user.id !== undefined && isCouncilAdmin && (
                                <Button type="primary" danger size="large" onClick={remove} icon={<DeleteOutlined />}>
                                    {desktop && <FormattedMessage id="button.delete" tagName="span" />}
                                </Button>
                            )}
                            <Link to={`/councils/${user?.councilId}/users`}>
                                <Button size="large" icon={<ArrowLeftOutlined />}>
                                    {desktop && <FormattedMessage id="button.back" tagName="span" />}
                                </Button>
                            </Link>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </LayoutComponent>
    );
};

export default CouncilUserPage;
type ParamsType = { id: string };
