import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import statisticsApi from '../../../apis/StatisticsApi';
import { StatsReceivableRegistrationIdentifierAndTaxAndFiscalYear, StatsReceivableRegistrationIdentifierAndTaxAndFiscalYears } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import styles from './StatisticsReceivableByRegistrationIdentifierTaxFiscalYearsComponent.module.scss';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';

/**
 *
 * @param props the props
 * @returns the statistics receivable by registration identifier, tax and fiscal year component
 */
const StatisticsReceivableByRegistrationIdentifierTaxFiscalYearsComponent: React.FC<Props> = (props) => {
    const { councilId, startDate, endDate } = props;

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading' | 'loading'>();
    const [receivablesByRegistrationIdentifierAndTaxAndFiscalYears, setReceivablesByRegistrationIdentifierAndTaxAndFiscalYears] =
        useState<StatsReceivableRegistrationIdentifierAndTaxAndFiscalYears>();
    const [, setCouncilId] = useState<number>(councilId);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                setCouncilId(councilId);
                const receivablesByRegistrationIdentifierAndTaxAndFiscalYears = await statisticsApi.getReceivableRegistrationIdentifierTaxFiscalYears(
                    councilId,
                    startDate,
                    endDate
                );
                setReceivablesByRegistrationIdentifierAndTaxAndFiscalYears(receivablesByRegistrationIdentifierAndTaxAndFiscalYears);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, endDate, intl, startDate]);

    /*** HOOKS ***/
    /*** METHODS ***/

    /*** VISUAL ***/

    const columns: ColumnsType<StatsReceivableRegistrationIdentifierAndTaxAndFiscalYear> = [
        {
            title: <FormattedMessage id="statsReceivableRegistrationIdentifierAndTaxAndFiscalYear.receivableRegistrationIdentifier" />,
            dataIndex: 'receivableRegistrationIdentifier',
            key: 'receivableRegistrationIdentifier',
            align: 'center',
            width: 180
        },
        {
            title: <FormattedMessage id="statsReceivableRegistrationIdentifierAndTaxAndFiscalYear.tax" />,
            dataIndex: 'tax',
            key: 'tax',
            render: (value: string, statsReceivableRegistrationIdentifierAndTaxAndFiscalYear: StatsReceivableRegistrationIdentifierAndTaxAndFiscalYear) => (
                <ConstantLabel value={value} prefix="receivable.tax." />
            )
        },
        {
            title: <FormattedMessage id="statsReceivableRegistrationIdentifierAndTaxAndFiscalYear.fiscalYear" />,
            dataIndex: 'fiscalYear',
            key: 'fiscalYear',
            align: 'center',
            width: 180
        },

        {
            title: <FormattedMessage id="statsReceivableRegistrationIdentifierAndTaxAndFiscalYear.amount.principal" />,
            dataIndex: ['amount', 'principal'],
            key: 'amountPrincipal',
            align: 'right',
            width: 180,

            render: (value: number, statsReceivableRegistrationIdentifierAndTaxAndFiscalYear: StatsReceivableRegistrationIdentifierAndTaxAndFiscalYear) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="statsReceivableRegistrationIdentifierAndTaxAndFiscalYear.count" />,
            dataIndex: 'count',
            key: 'count',
            align: 'right',
            width: 180
        }
    ];

    const statsReceivableRegistrationIdentifierAndTaxAndFiscalYears =
        receivablesByRegistrationIdentifierAndTaxAndFiscalYears?.statsReceivableRegistrationIdentifierAndTaxAndFiscalYears
            ? receivablesByRegistrationIdentifierAndTaxAndFiscalYears?.statsReceivableRegistrationIdentifierAndTaxAndFiscalYears
            : [];

    return (
        <div className={styles.container}>
            <Table
                className={styles.table}
                dataSource={statsReceivableRegistrationIdentifierAndTaxAndFiscalYears}
                columns={columns}
                pagination={false}
                rowKey="id"
                showSorterTooltip={false}
                loading={loading === 'loading'}
                summary={(pageData) => {
                    return (
                        <>
                            <Table.Summary.Row className={styles.summary}>
                                <Table.Summary.Cell index={0} align="center">
                                    <FormattedMessage id="statsReceivableRegistrationIdentifierAndTaxAndFiscalYear.total" />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} />
                                <Table.Summary.Cell index={2} />
                                <Table.Summary.Cell index={3} align="right">
                                    {receivablesByRegistrationIdentifierAndTaxAndFiscalYears &&
                                        receivablesByRegistrationIdentifierAndTaxAndFiscalYears.amount && (
                                            <FormattedNumber
                                                value={receivablesByRegistrationIdentifierAndTaxAndFiscalYears!.amount.principal!}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                                style={'currency' as any}
                                                currency="EUR"
                                            />
                                        )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4} align="right">
                                    {receivablesByRegistrationIdentifierAndTaxAndFiscalYears &&
                                        receivablesByRegistrationIdentifierAndTaxAndFiscalYears.count && (
                                            <FormattedNumber
                                                value={receivablesByRegistrationIdentifierAndTaxAndFiscalYears!.count}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                            />
                                        )}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </div>
    );
};
export default StatisticsReceivableByRegistrationIdentifierTaxFiscalYearsComponent;

interface Props {
    councilId: number;
    startDate: Dayjs;
    endDate: Dayjs;
}
