import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, InputNumber, Row, Select, Space, message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import nationalParameterApi from '../../../apis/NationalParameterApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import { NationalParameter } from '../../../models/Entities';
import alertService from '../../../services/AlertService';

/**
 * Returns the national parameter page.
 * @returns the national parameter page.
 */
const NationalParameterPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [desktop] = useResponsiveLayout();
    const params = useParams<ParamsType>();
    const [loading, setLoading] = useState<'initializing' | 'loading' | 'deleting'>();
    const [nationalParameter, setNationalParameter] = useState<NationalParameter>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('initializing');
                const nationalParameterId = params.id;
                let nationalParameter: NationalParameter;
                if (nationalParameterId === 'new') {
                    nationalParameter = {};
                } else {
                    nationalParameter = await nationalParameterApi.get(+nationalParameterId!);
                }
                form.setFieldsValue(nationalParameter);
                setNationalParameter(nationalParameter);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [form, intl, params.id]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');

            let updatedNationalParameter: NationalParameter = Object.assign({}, nationalParameter, values);
            updatedNationalParameter = await nationalParameterApi.create(updatedNationalParameter);
            setNationalParameter(updatedNationalParameter);

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const range = dayjs().year() - 2018;
    const yearOptions = Array.from({ length: range }, (x, i) => i + 2020)
        .reverse()
        .map((year) => ({ value: year, label: year }));

    return (
        <LayoutComponent
            title={<FormattedMessage id="companies.title" />}
            menu="nationalParameters"
            path={[
                { path: '/national-parameters', name: <FormattedMessage id="nationalParameters.title" /> },
                { path: `/national-parameters/${params.id}`, name: nationalParameter?.id }
            ]}
        >
            <Form form={form} onFinish={save} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col lg={4}>
                        <Form.Item
                            label={<FormattedMessage id="nationalParameter.fiscalYear" />}
                            name="fiscalYear"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select size="large" disabled={!!nationalParameter && !!nationalParameter.id} options={[...yearOptions]} />
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item
                            label={<FormattedMessage id="nationalParameter.interestRate" />}
                            name="interestRate"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber
                                size="large"
                                controls={false}
                                min={0}
                                max={100}
                                className="input-number"
                                disabled={!!nationalParameter && !!nationalParameter.id}
                                suffix="%"
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item
                            label={<FormattedMessage id="nationalParameter.mailCosts" />}
                            name="mailCosts"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber
                                size="large"
                                controls={false}
                                min={0}
                                className="input-number"
                                disabled={!!nationalParameter && !!nationalParameter.id}
                                suffix="€"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]} className="buttons">
                    <Col span={24}>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                loading={loading === 'loading'}
                                icon={<SaveOutlined />}
                                disabled={!!nationalParameter && !!nationalParameter.id}
                            >
                                <FormattedMessage id="button.save" tagName="span" />
                            </Button>
                            <Link to="/national-parameters">
                                <Button size="large" icon={<ArrowLeftOutlined />}>
                                    {desktop && <FormattedMessage id="button.back" tagName="span" />}
                                </Button>
                            </Link>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </LayoutComponent>
    );
};
export default NationalParameterPage;
type ParamsType = { id: string };
