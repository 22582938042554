import { Col, DatePicker, Form, Row, Select, Tabs } from 'antd';
import { TabsProps } from 'antd/lib';
import { FormattedMessage } from 'react-intl';
import { notificationTypes } from '../../models/Types';
import stringService from '../../services/StringService';
import AddressComponent from '../AddressComponent/AddressComponent';
import styles from '../TaxpayerAddressComponent/TaxpayerAddressComponent.module.scss';
import ConstantLabel from '../ConstantLabel/ConstantLabel';

const TaxpayerAddressComponent = (props: Props) => {
    const { field, hideHeader, disabled } = props;

    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** VISUAL ***/

    const tabs: TabsProps['items'] = [
        {
            key: 'fiscalAddress',
            label: <FormattedMessage id="address.label" />,
            forceRender: true,
            children: <AddressComponent field={field} hideHeader={hideHeader} disabled={disabled} />
        },
        {
            key: 'electronicNotifications',
            label: <FormattedMessage id="address.electronicNotifications" />,
            forceRender: true,
            children: <ElectronicNotificationsComponent disabled={disabled} />
        },
        {
            key: 'directDebit',
            label: <FormattedMessage id="address.directDebit" />,
            forceRender: true,
            children: <DirectDebitComponent />,
            disabled: true
        }
    ];

    return (
        <>
            <Tabs defaultActiveKey="fiscalAddress" type="card" size="large" items={tabs} />
        </>
    );
};

export default TaxpayerAddressComponent;

interface Props {
    field: string;
    hideHeader?: boolean;
    disabled?: boolean;
}

const ElectronicNotificationsComponent = (props: ElectronicNotificationsComponentProps) => {
    const { disabled } = props;

    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** VISUAL ***/

    const notificationOptions = notificationTypes.map((nt) => ({ value: nt, label: <ConstantLabel value={nt} prefix="taxpayer.notificationType." /> }));

    return (
        <>
            <Row gutter={[28, 0]}>
                <Col lg={10}>
                    <Form.Item
                        label={<FormattedMessage id="taxpayer.notification" />}
                        name="notification"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select
                            size="large"
                            allowClear
                            showSearch
                            filterOption={stringService.filterOptions}
                            disabled={disabled}
                            options={[...notificationOptions]}
                        />
                    </Form.Item>
                </Col>
                <Col lg={4}>
                    <Form.Item label={<FormattedMessage id="taxpayer.notificationDate" />} name="notificationDate" className={styles.datePicker}>
                        <DatePicker size="large" format="lll" className={styles.datePicker} />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

interface ElectronicNotificationsComponentProps {
    disabled?: boolean;
}

const DirectDebitComponent = () => {
    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** VISUAL ***/

    return (
        <>
            <Row gutter={[28, 0]}>
                <Col lg={4}>
                    <Form.Item label={<FormattedMessage id="taxpayer.directDebitDate" />} name="directDebitDate" className={styles.datePicker}>
                        <DatePicker size="large" format="lll" className={styles.datePicker} />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};
