import { Dayjs } from 'dayjs';
import CancellationsTableComponent from './CancellationsTableComponent/CancellationsTableComponent';
import styles from './InvoicesDataComponent.module.scss';
import PaymentsTableComponent from './PaymentsTableComponent/PaymentsTableComponent';

/**
 *
 * @param props the props
 * @returns the invoices data component
 */
const InvoicesDataComponent: React.FC<Props> = (props) => {
    const { councilId, startDate, endDate } = props;

    /*** VISUAL ***/

    return (
        <div className={styles.container}>
            <PaymentsTableComponent councilId={councilId} startDate={startDate} endDate={endDate} process="VOLUNTARY" />
            <PaymentsTableComponent councilId={councilId} startDate={startDate} endDate={endDate} process="EXECUTIVE" />
            <CancellationsTableComponent councilId={councilId} startDate={startDate} endDate={endDate} process="VOLUNTARY" />
            <CancellationsTableComponent councilId={councilId} startDate={startDate} endDate={endDate} process="EXECUTIVE" />
        </div>
    );
};
export default InvoicesDataComponent;

interface Props {
    councilId: number;
    startDate: Dayjs;
    endDate: Dayjs;
}
