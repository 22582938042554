import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Result, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CensusDocument, Council } from '../../../models/Entities';

/**
 * Returns the census documents result component.
 * @returns the census documents result component.
 */
const CensusDocumentsResultComponent: React.FC<Props> = (props) => {
    const { council, censusDocument } = props;

    /***VISUAL***/

    return (
        <>
            <Row gutter={[28, 0]}>
                {censusDocument.file?.name && (
                    <Col span={24}>
                        <Result
                            status="403"
                            title={<FormattedMessage id="censusDocuments.import.success" />}
                            subTitle={<FormattedMessage id="censusDocuments.import.success.desc" />}
                            extra={
                                <Link to={`/councils/${council.id}/census-urban-properties`}>
                                    <Button size="large" type="primary" icon={<ArrowLeftOutlined />}>
                                        <FormattedMessage id="censusDocuments.import.steps.3.back" tagName="span" />
                                    </Button>
                                </Link>
                            }
                        />
                    </Col>
                )}
            </Row>
        </>
    );
};
export default CensusDocumentsResultComponent;
interface Props {
    council: Council;
    censusDocument: CensusDocument;
}
