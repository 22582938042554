import { ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, message, Popconfirm, Row, Select, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import censusUrbanPropertyApi from '../../../apis/CensusUrbanPropertyApi';
import CensusUrbanPropertyTaxpayerModal from '../../../components/CensusUrbanPropertyTaxpayerModal/CensusUrbanPropertyTaxpayerModal';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomContext from '../../../contexts/CustomContext';
import CustomCouncilContext from '../../../contexts/CustomCouncilContext';
import { BreadcrumbItem } from '../../../models/Elements';
import { CensusUrbanProperty, CensusUrbanPropertyTaxpayer, Council } from '../../../models/Entities';
import { statusTypes } from '../../../models/Types';
import alertService from '../../../services/AlertService';
import rolesService from '../../../services/RolesService';
import styles from './CensusUrbanPropertyPage.module.scss';

/**
 * Returns the census urban property page.
 * @returns the census urban property page.
 */
const CensusUrbanPropertyPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const [form] = Form.useForm();
    const { auth } = useContext(CustomContext);
    const { council } = useContext(CustomCouncilContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<'initializing' | 'loading' | 'refreshing' | 'deleting'>();
    const [censusUrbanProperty, setCensusUrbanProperty] = useState<CensusUrbanProperty>();
    const [desktop] = useResponsiveLayout();
    const [censusUrbanPropertyTaxpayer, setCensusUrbanPropertyTaxpayer] = useState<CensusUrbanPropertyTaxpayer>();

    /*** EFFECTS ***/
    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('initializing');
                    const censusUrbanProperty: CensusUrbanProperty = await censusUrbanPropertyApi.get(+params.id!);
                    form.setFieldsValue(censusUrbanProperty);
                    setCensusUrbanProperty(censusUrbanProperty);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [council, form, intl, params.id]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');

            let updatedCensusUrbanProperty: CensusUrbanProperty = Object.assign({}, censusUrbanProperty, values);
            updatedCensusUrbanProperty = await censusUrbanPropertyApi.update(updatedCensusUrbanProperty);
            setCensusUrbanProperty(updatedCensusUrbanProperty);

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const remove = async () => {
        try {
            if (censusUrbanProperty && censusUrbanProperty.id) {
                setLoading('deleting');
                await censusUrbanPropertyApi.delete(censusUrbanProperty.id);
                message.success(intl.formatMessage({ id: 'status.deleted' }));
                navigate(`/councils/${council.id}/census-urban-properties`);
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const showModal = (censusUrbanPropertyTaxpayer: CensusUrbanPropertyTaxpayer) => {
        setCensusUrbanPropertyTaxpayer(censusUrbanPropertyTaxpayer);
    };

    const hideModal = () => {
        setCensusUrbanPropertyTaxpayer(undefined);
    };

    const updateCensusUrbanPropertyTaxpayer = (censusUrbanPropertyTaxpayer: CensusUrbanPropertyTaxpayer) => {
        const updatedCensusUrbanProperty: CensusUrbanProperty = Object.assign({}, censusUrbanProperty);
        updatedCensusUrbanProperty.taxpayers = updatedCensusUrbanProperty.taxpayers.map((t) => {
            return t.id === censusUrbanPropertyTaxpayer.id ? censusUrbanPropertyTaxpayer : t;
        });
        setCensusUrbanProperty(updatedCensusUrbanProperty);

        hideModal();
    };

    /*** VISUAL ***/

    const statusOptions = statusTypes.map((s) => ({ value: s, label: <ConstantLabel value={s} prefix="status.type." /> }));

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);
    const items = censusUrbanProperty && censusUrbanProperty.taxpayers ? censusUrbanProperty.taxpayers : [];

    const columns: ColumnsType<CensusUrbanPropertyTaxpayer> = [
        {
            title: <FormattedMessage id="censusUrbanProperty.taxpayer.identifier" />,
            dataIndex: ['taxpayer', 'identifier'],
            key: 'taxpayerIdentifier',
            width: 120,
            fixed: 'left',
            align: 'center',
            sorter: false,
            defaultSortOrder: 'ascend',
            render: (value: number, censusUrbanPropertyTaxpayer: CensusUrbanPropertyTaxpayer) => (
                <Button type="link" onClick={() => showModal(censusUrbanPropertyTaxpayer)}>
                    {value}
                </Button>
            )
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.taxpayer.firstName" />,
            dataIndex: ['taxpayer', 'firstName'],
            key: 'taxpayerFirstName',
            width: 120,
            fixed: 'left',
            align: 'center'
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.taxpayer.lastName" />,
            dataIndex: ['taxpayer', 'lastName'],
            key: 'taxpayerLastName',
            width: 120,
            fixed: 'left',
            align: 'center'
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.taxpayer.secondLastName" />,
            dataIndex: ['taxpayer', 'secondLastName'],
            key: 'taxpayerLastName',
            width: 120,
            fixed: 'left',
            align: 'center'
        },

        {
            title: <FormattedMessage id="censusUrbanProperty.taxpayer.address.street" />,
            dataIndex: ['taxpayer', 'address', 'street'],
            key: 'addressStreet',
            width: 140,
            fixed: 'left',
            align: 'center'
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.rights" />,
            dataIndex: 'rights',
            key: 'rights',
            align: 'center',
            width: 120
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.rightsPercentage" />,
            dataIndex: 'rightsPercentage',
            key: 'rightsPercentage',
            align: 'center',
            width: 120
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="councilBanks.title" />}
            menu="councils"
            path={PathComponent(council, censusUrbanProperty!)}
            council={council}
        >
            <Form form={form} onFinish={save} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.fixNumber" />}
                            name="fixNumber"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.taxpayer.identifier" />}
                            name={['taxpayer', 'identifier']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.taxpayer.firstName" />}
                            name={['taxpayer', 'firstName']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.taxpayer.lastName" />}
                            name={['taxpayer', 'lastName']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.taxpayer.secondLastName" />}
                            name={['taxpayer', 'secondLastName']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.rights" />}
                            name="rights"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.rightsPercentage" />}
                            name="rightsPercentage"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} className="input-number" addonAfter="%" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.propertyLocation" />}
                            name="propertyLocation"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.propertyLocation2" />}
                            name="propertyLocation2"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.propertyLocation3" />}
                            name="propertyLocation3"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.cadastralReference" />}
                            name="cadastralReference"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} className="input-number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.constructedArea" />}
                            name="constructedArea"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} className="input-number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.landArea" />}
                            name="landArea"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} className="input-number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.constructionValue" />}
                            name="constructionValue"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} className="input-number" addonAfter="€" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.landValue" />}
                            name="landValue"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} className="input-number" addonAfter="€" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.cadastralValue" />}
                            name="cadastralValue"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} className="input-number" addonAfter="€" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.taxableBase" />}
                            name="taxableBase"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} className="input-number" addonAfter="€" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.classification" />}
                            name="classification"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.taxRate" />}
                            name="taxRate"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} className="input-number" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.participationRate" />}
                            name="participationRate"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} className="input-number" addonAfter="%" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.bonusRate" />}
                            name="bonusRate"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} className="input-number" addonAfter="%" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.bonifiedAmount" />}
                            name="bonifiedAmount"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} className="input-number" addonAfter="€" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.fee" />}
                            name="fee"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} className="input-number" addonAfter="€" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="censusUrbanProperty.status" />}
                            name="status"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select size="large" options={[...statusOptions]} />
                        </Form.Item>
                    </Col>
                </Row>
                <Table
                    dataSource={items}
                    columns={columns}
                    rowKey="id"
                    //onChange={handleTableChange}
                    showSorterTooltip={false}
                    pagination={false}
                    loading={loading === 'loading'}
                    className={`table ${styles.table}`}
                    scroll={{ x: 400 }}
                />
                <Row gutter={[28, 0]} className="buttons">
                    <Col span={16}>
                        <Space>
                            {isAdmin && (
                                <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                                    <FormattedMessage id="button.save" tagName="span" />
                                </Button>
                            )}
                            {censusUrbanProperty?.id !== undefined && isAdmin && (
                                <Popconfirm title={intl.formatMessage({ id: 'censusUrbanPropertyPage.deleteModal.title' })}>
                                    <Button type="primary" danger size="large" onClick={remove} icon={<DeleteOutlined />}>
                                        {desktop && <FormattedMessage id="button.delete" tagName="span" />}
                                    </Button>
                                </Popconfirm>
                            )}
                            <Link to={`/councils/${council.id}/census-urban-properties`}>
                                <Button size="large" icon={<ArrowLeftOutlined />}>
                                    {desktop && <FormattedMessage id="button.back" tagName="span" />}
                                </Button>
                            </Link>
                        </Space>
                    </Col>
                </Row>
            </Form>
            {censusUrbanPropertyTaxpayer && (
                <CensusUrbanPropertyTaxpayerModal
                    censusUrbanPropertyTaxpayer={censusUrbanPropertyTaxpayer}
                    onSave={updateCensusUrbanPropertyTaxpayer}
                    onCancel={hideModal}
                />
            )}
        </LayoutComponent>
    );
};

export default CensusUrbanPropertyPage;
type ParamsType = { id: string };

/**
 * Returns the path of the census urban property page.
 * @param council the council
 * @returns  the path
 */
const PathComponent = (council: Council, censusUrbanProperty: CensusUrbanProperty): BreadcrumbItem[] => {
    return [
        { path: '/councils', name: <FormattedMessage id="councils.title" /> },
        { path: `/councils/${council.id}`, name: council.name },
        { path: `/councils/${council.id}/census-urban-properties`, name: <FormattedMessage id="censusUrbanProperties.title" /> },
        { path: `/councils/${council.id}/census-urban-properties/${censusUrbanProperty?.id}`, name: censusUrbanProperty?.id }
    ];
};
