import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, DescriptionsProps, Divider, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import censusUrbanPropertyApi from '../../../../apis/CensusUrbanPropertyApi';
import receivableVoluntaryRegistrationApi from '../../../../apis/ReceivableVoluntaryRegistrationApi';
import ProvinceComponent from '../../../../components/ProvinceComponent/ProvinceComponent';
import { CensusUrbanProperty, Receivable, ReceivableVoluntaryRegistrationUrbanProperty, Taxpayer } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import styles from './ReceivableVoluntaryRegistrationUrbanPropertyComponent.module.scss';

const ReceivableVoluntaryRegistrationUrbanPropertyComponent = (props: Props) => {
    const { registrationUrbanProperty, receivable } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading' | 'loading'>();
    const [censusUrbanProperty, setCensusUrbanProperty] = useState<CensusUrbanProperty>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const censusUrbanProperty = await censusUrbanPropertyApi.get(registrationUrbanProperty.censusUrbanPropertyId!);
                setCensusUrbanProperty(censusUrbanProperty);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, registrationUrbanProperty.censusUrbanPropertyId]);

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');
            const document = await receivableVoluntaryRegistrationApi.download(registrationUrbanProperty.id!, 'IMPUESTO_BIENES_INMUEBLES_DE_NATURALEZA_URBANA');
            FileSaver.saveAs(document, intl.formatMessage({ id: 'receivablesVoluntary.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const taxpayers: Taxpayer[] = censusUrbanProperty?.taxpayers ? censusUrbanProperty?.taxpayers : [];

    const items: DescriptionsProps['items'] = [
        {
            key: 'firstName',
            label: <FormattedMessage id="taxpayer.firstName" />,
            children: censusUrbanProperty?.taxpayer?.firstName
        },
        {
            key: 'lastName',
            label: <FormattedMessage id="taxpayer.lastName" />,
            children: censusUrbanProperty?.taxpayer?.lastName
        },
        {
            key: 'secondLastName',
            label: <FormattedMessage id="taxpayer.secondLastName" />,
            children: censusUrbanProperty?.taxpayer?.secondLastName
        },
        {
            key: 'identifier',
            label: <FormattedMessage id="taxpayer.identifier" />,
            children: censusUrbanProperty?.taxpayer?.identifier
        },
        {
            key: 'phone',
            label: <FormattedMessage id="address.phone" />,
            children: censusUrbanProperty?.taxpayer?.address?.phone
        },
        {
            key: 'email',
            label: <FormattedMessage id="address.email" />,
            children: censusUrbanProperty?.taxpayer?.address?.email
        },
        {
            key: 'street',
            label: <FormattedMessage id="address.street" />,
            children: censusUrbanProperty?.taxpayer?.address?.street
        },
        {
            key: 'city',
            label: <FormattedMessage id="address.city" />,
            children: censusUrbanProperty?.taxpayer?.address?.city
        },
        {
            key: 'province',
            label: <FormattedMessage id="address.province" />,
            children: <ProvinceComponent provinceId={censusUrbanProperty?.taxpayer?.address?.province} />
        },
        {
            key: 'postcode',
            label: <FormattedMessage id="address.postcode" />,
            children: censusUrbanProperty?.taxpayer?.address?.postcode
        },
        {
            key: 'fixNumber',
            label: <FormattedMessage id="censusUrbanProperty.fixNumber" />,
            children: censusUrbanProperty?.fixNumber
        },
        {
            key: 'rights',
            label: <FormattedMessage id="censusUrbanProperty.rights" />,
            children: censusUrbanProperty?.rights
        },
        {
            key: 'rightsPercentage',
            label: <FormattedMessage id="censusUrbanProperty.rightsPercentage" />,
            children: censusUrbanProperty?.rightsPercentage
        },
        {
            key: 'propertyLocation',
            label: <FormattedMessage id="censusUrbanProperty.propertyLocation" />,
            children: censusUrbanProperty?.propertyLocation
        },
        {
            key: 'propertyLocation2',
            label: <FormattedMessage id="censusUrbanProperty.propertyLocation2" />,
            children: censusUrbanProperty?.propertyLocation2
        },
        {
            key: 'propertyLocation3',
            label: <FormattedMessage id="censusUrbanProperty.propertyLocation3" />,
            children: censusUrbanProperty?.propertyLocation3
        },
        {
            key: 'cadastralReference',
            label: <FormattedMessage id="censusUrbanProperty.cadastralReference" />,
            children: censusUrbanProperty?.cadastralReference
        },
        {
            key: 'constructedArea',
            label: <FormattedMessage id="censusUrbanProperty.constructedArea" />,
            children: censusUrbanProperty?.constructedArea
        },
        {
            key: 'landArea',
            label: <FormattedMessage id="censusUrbanProperty.landArea" />,
            children: censusUrbanProperty?.landArea
        },
        {
            key: 'constructionValue',
            label: <FormattedMessage id="censusUrbanProperty.constructionValue" />,
            children: censusUrbanProperty?.constructionValue
        },
        {
            key: 'landValue',
            label: <FormattedMessage id="censusUrbanProperty.landValue" />,
            children: censusUrbanProperty?.landValue
        },
        {
            key: 'cadastralValue',
            label: <FormattedMessage id="censusUrbanProperty.cadastralValue" />,
            children: censusUrbanProperty?.cadastralValue
        },
        {
            key: 'taxableBase',
            label: <FormattedMessage id="censusUrbanProperty.taxableBase" />,
            children: censusUrbanProperty?.taxableBase
        },
        {
            key: 'classification',
            label: <FormattedMessage id="censusUrbanProperty.classification" />,
            children: censusUrbanProperty?.classification
        },
        {
            key: 'taxRate',
            label: <FormattedMessage id="censusUrbanProperty.taxRate" />,
            children: censusUrbanProperty?.taxRate
        },
        {
            key: 'participationRate',
            label: <FormattedMessage id="censusUrbanProperty.participationRate" />,
            children: censusUrbanProperty?.participationRate
        },
        {
            key: 'bonusRate',
            label: <FormattedMessage id="censusUrbanProperty.bonusRate" />,
            children: censusUrbanProperty?.bonusRate
        },
        {
            key: 'bonifiedAmount',
            label: <FormattedMessage id="censusUrbanProperty.bonifiedAmount" />,
            children: censusUrbanProperty?.bonifiedAmount
        },
        {
            key: 'fee',
            label: <FormattedMessage id="censusUrbanProperty.fee" />,
            children: censusUrbanProperty?.fee
        },
        {
            key: 'started',
            label: <FormattedMessage id="receivable.started" />,
            children: receivable.started && (
                <FormattedDate value={receivable.started as any} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
            )
        },
        {
            key: 'expired',
            label: <FormattedMessage id="receivable.expired" />,
            children: receivable.expired && (
                <FormattedDate value={receivable.expired as any} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
            )
        },

        {
            key: 'created',
            label: <FormattedMessage id="audit.created" />,
            children: censusUrbanProperty?.audit && (
                <FormattedDate value={censusUrbanProperty.audit.created as any} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
            )
        }
    ];

    return (
        <>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <Descriptions layout="horizontal" items={items} size="default" column={4} />
                </Col>
            </Row>
            {taxpayers.length > 0 && <TaxpayerComponent taxpayers={taxpayers} />}
            <Row gutter={[28, 0]} className={styles.buttons}>
                <Col span={24}>
                    <Button icon={<CloudDownloadOutlined />} onClick={download} loading={loading === 'downloading'}>
                        <FormattedMessage id="button.download" tagName="span" />
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default ReceivableVoluntaryRegistrationUrbanPropertyComponent;

interface Props {
    registrationUrbanProperty: ReceivableVoluntaryRegistrationUrbanProperty;
    receivable: Receivable;
}

/**
 * The taxpayers component with the taxpayer information.
 * @param props the props
 * @returns the taxpayer component
 */
const TaxpayerComponent: React.FC<TaxpayerComponentProps> = (props) => {
    const { taxpayers } = props;

    /*** HOOKS ***/

    /*** METHODS ***/

    /*** VISUAL ***/

    const columns: ColumnsType<Taxpayer> = [
        {
            title: <FormattedMessage id="taxpayer.firstName" />,
            dataIndex: ['taxpayer', 'firstName'],
            key: 'taxpayer.firstName'
        },
        {
            title: <FormattedMessage id="taxpayer.lastName" />,
            dataIndex: ['taxpayer', 'lastName'],
            key: 'lastName'
        },
        {
            title: <FormattedMessage id="taxpayer.secondLastName" />,
            dataIndex: ['taxpayer', 'secondLastName'],
            key: 'secondLastName'
        },
        {
            title: <FormattedMessage id="taxpayer.identifier" />,
            dataIndex: ['taxpayer', 'identifier'],
            key: 'identifier'
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.rights" />,
            dataIndex: 'rights',
            key: 'rights',
            align: 'center',
            width: 110
        },
        {
            title: <FormattedMessage id="censusUrbanProperty.rightsPercentage" />,
            dataIndex: 'rightsPercentage',
            key: 'rightsPercentage',
            align: 'right',
            width: 150,
            render: (value: number) => <FormattedNumber value={value / 100} minimumFractionDigits={2} maximumFractionDigits={2} style={'percent' as any} />
        },
        {
            title: <FormattedMessage id="address.street" />,
            dataIndex: ['taxpayer', 'address', 'street'],
            key: 'addressStreet'
        },
        {
            title: <FormattedMessage id="address.postcode.short" />,
            dataIndex: ['taxpayer', 'address', 'postcode'],
            key: 'addressPostcode',
            width: 100
        },
        {
            title: <FormattedMessage id="address.city" />,
            dataIndex: ['taxpayer', 'address', 'city'],
            key: 'addressCity'
        }
    ];

    return (
        <>
            <Col span={24}>
                <Divider orientation="left" plain>
                    <FormattedMessage id="censusUrbanProperty.taxpayers" />
                </Divider>
                <Table
                    dataSource={taxpayers}
                    columns={columns}
                    rowKey="id"
                    size="small"
                    //onChange={handleTableChange}
                    showSorterTooltip={false}
                    pagination={false}
                    className={`table ${styles.table}`}
                    scroll={{ x: 400 }}
                />
            </Col>
        </>
    );
};
interface TaxpayerComponentProps {
    taxpayers: Taxpayer[];
}
