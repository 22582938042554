import { CloudUploadOutlined, DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, InputNumber, List, Modal, Row, Upload, message } from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { UploadFile } from 'antd/lib';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import announcementApi from '../../apis/AnnouncementApi';
import { Announcement } from '../../models/Entities';
import alertService from '../../services/AlertService';
import FileSizeComponent from '../FileSizeComponent/FileSizeComponent';
import styles from './AnnouncementPublicationModal.module.scss';

const AnnouncementPublicationModal: React.FC<Props> = (props) => {
    const { announcementId } = props;
    const maxFileSize = 5 * 1024 * 1024;

    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<'loading' | 'saving'>();
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [announcement, setAnnouncement] = useState<Announcement>();

    useEffect(() => {
        const init = async () => {
            try {
                if (announcementId) {
                    setLoading('loading');

                    const announcement = await announcementApi.get(announcementId);
                    setAnnouncement(announcement);
                    if (announcement.bulletinFile && announcement.bulletinFile.name) {
                        setFiles([{ name: announcement.bulletinFile.name } as any]);
                    }
                    form.setFieldsValue(announcement);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, announcementId, form]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('saving');

            const updatedAnnouncement: Announcement = Object.assign({}, announcement, values);
            const attachment: UploadFile | undefined = files.length > 0 && files[0] instanceof File ? files[0] : undefined;
            await announcementApi.update(updatedAnnouncement, attachment);
            props.onSave();

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error: any) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    // cancel
    const cancel = async () => {
        props.onCancel();
    };

    // validate file
    const validateFile = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void): Promise<void> | void => {
        if (value && value.bulletinFile && !isFileSizeValid(value.bulletinFile)) {
            callback(intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };

    const isFileSizeValid = (file: UploadFile) => !file.size || file.size <= maxFileSize;

    // upload file
    const uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!isFileSizeValid(file)) {
            setFiles(files);
        } else {
            files.push(file);
            setFiles(files);
        }

        return false;
    };

    //remove file
    const removeFile = () => {
        const files: UploadFile[] = [];
        form.setFieldsValue({
            bulletinFile: files
        });
        setFiles(files);
    };

    /*** VISUAL ***/

    return (
        <Modal
            title={<FormattedMessage id="announcementPublicationModal.title" />}
            open={true}
            onCancel={cancel}
            okText={<FormattedMessage id="button.save" tagName="span" />}
            okButtonProps={{ loading: loading === 'saving', size: 'large', htmlType: 'submit' }}
            cancelButtonProps={{ disabled: loading === 'saving', size: 'large' }}
            width={1000}
            destroyOnClose
            modalRender={(dom) => (
                <Form form={form} colon={false} layout="vertical" onFinish={(values) => save(values)}>
                    {dom}
                </Form>
            )}
        >
            <Row gutter={[28, 0]} className={styles.title}>
                <Col span={8}>
                    <Form.Item
                        label={<FormattedMessage id="announcementPublicationModal.bulletinPublished" />}
                        name="bulletinPublished"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <DatePicker className={styles.date} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={<FormattedMessage id="announcementPublicationModal.bulletinNumber" />}
                        name="bulletinNumber"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <InputNumber maxLength={20} className={styles.number} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={<FormattedMessage id="announcementPublicationModal.expired" />} name="expired">
                        <DatePicker className={styles.date} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <Form.Item
                        name="bulletinFile"
                        valuePropName="files"
                        label={
                            <>
                                <span>
                                    <FormattedMessage id="announcementPublicationModal.upload" />
                                </span>
                            </>
                        }
                        rules={[
                            {
                                validator: validateFile
                            },
                            { required: true, message: <FormattedMessage id="status.mandatory" /> }
                        ]}
                        extra={
                            <>
                                <FormattedMessage id="attachment.size" /> <FileSizeComponent value={maxFileSize} />
                            </>
                        }
                    >
                        <Upload.Dragger beforeUpload={uploadFile} fileList={files} showUploadList={false}>
                            <CloudUploadOutlined /> <FormattedMessage id="button.upload" />
                        </Upload.Dragger>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <List
                        itemLayout="horizontal"
                        dataSource={files}
                        locale={{ emptyText: <></> }}
                        renderItem={(file) => (
                            <List.Item actions={[<Button icon={<DeleteOutlined />} danger size="large" onClick={removeFile} />]}>
                                <List.Item.Meta avatar={<PaperClipOutlined />} title={file.name} description={<FileSizeComponent value={file.size} />} />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Modal>
    );
};
export default AnnouncementPublicationModal;

interface Props {
    announcementId: number;
    onSave: () => void;
    onCancel: () => void;
}
