import { Button, Divider, Table, Tooltip } from 'antd';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { FileExcelOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import alertService from '../../../../services/AlertService';
import billingApi from '../../../../apis/BillingApi';
import { Dayjs } from 'dayjs';
import { ProcessType } from '../../../../models/Types';
import { BillingPaymentsByFiscalYearCreatedYearTax, BillingPaymentsFiscalYearCreatedYearTax } from '../../../../models/Entities';
import FileSaver from 'file-saver';
import styles from './PaymentsTableComponent.module.scss';
import { ColumnsType } from 'antd/es/table';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';

/**
 *
 * @param props the props
 * @returns the payments table component
 */
const PaymentsTableComponent: React.FC<Props> = (props) => {
    const { councilId, startDate, endDate, process } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading' | 'loading'>();
    const [paymentsByFiscalYearCreatedYearTax, setPaymentsByFiscalYearCreatedYearTax] = useState<BillingPaymentsByFiscalYearCreatedYearTax>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const paymentsByFiscalYearCreatedYearTax = await billingApi.getPaymentFiscalYearCreatedYearTax(councilId, startDate, endDate, process);
                setPaymentsByFiscalYearCreatedYearTax(paymentsByFiscalYearCreatedYearTax);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, endDate, intl, process, startDate]);

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');

            const document = await billingApi.downloadPaymentFiscalYearCreatedYearTax(councilId, startDate, endDate, process);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'invoicePaymentsFiscalYearCreatedYearTax.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const billingPaymentsFiscalYearCreatedYearTaxes = paymentsByFiscalYearCreatedYearTax?.billingPaymentsFiscalYearCreatedYearTaxes
        ? paymentsByFiscalYearCreatedYearTax.billingPaymentsFiscalYearCreatedYearTaxes
        : [];

    const paymentsColumns: ColumnsType<BillingPaymentsFiscalYearCreatedYearTax> = [
        {
            title: <FormattedMessage id="billingPaymentsFiscalYearCreatedYearTax.fiscalYear" />,
            dataIndex: 'fiscalYear',
            key: 'fiscalYear',
            align: 'center',
            width: 20
        },
        {
            title: <FormattedMessage id="billingPaymentsFiscalYearCreatedYearTax.createdYear" />,
            dataIndex: 'createdYear',
            key: 'createdYear',
            align: 'center',
            width: 20
        },
        {
            title: <FormattedMessage id="billingPaymentsFiscalYearCreatedYearTax.tax" />,
            dataIndex: 'tax',
            key: 'tax',
            align: 'left',
            render: (value: string, billingPaymentsFiscalYearCreatedYearTax: BillingPaymentsFiscalYearCreatedYearTax) => (
                <ConstantLabel value={value} prefix="receivable.tax." />
            )
        },

        {
            title: <FormattedMessage id="billingPaymentsFiscalYearCreatedYearTax.amount.principal" />,
            dataIndex: ['amount', 'principal'],
            key: 'amountPrincipal',
            align: 'right',
            width: 70,
            render: (value: number, billingPaymentsFiscalYearCreatedYearTax: BillingPaymentsFiscalYearCreatedYearTax) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        }
    ];

    return (
        <>
            <Divider orientation="left" className={styles.divider}>
                {process === 'VOLUNTARY' ? (
                    <FormattedMessage id="invoicesDataComponent.voluntaryPaymentsTable" />
                ) : (
                    <FormattedMessage id="invoicesDataComponent.executivePaymentsTable" />
                )}
            </Divider>
            <Tooltip title={<FormattedMessage id="button.download" />}>
                <Button size="large" icon={<FileExcelOutlined />} onClick={download} loading={loading === 'downloading'}>
                    <FormattedMessage id="button.download" tagName="span" />
                </Button>
            </Tooltip>
            <Table
                className={styles.table}
                dataSource={billingPaymentsFiscalYearCreatedYearTaxes}
                columns={paymentsColumns}
                pagination={false}
                rowKey="id"
                showSorterTooltip={false}
                loading={loading === 'loading'}
                summary={(pageData) => {
                    return (
                        <>
                            <Table.Summary.Row className={styles.summary}>
                                <Table.Summary.Cell index={0} align="center">
                                    <FormattedMessage id="billingPaymentsFiscalYearCreatedYearTax.total" />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} />
                                <Table.Summary.Cell index={2} />
                                <Table.Summary.Cell index={3} align="right">
                                    {paymentsByFiscalYearCreatedYearTax && paymentsByFiscalYearCreatedYearTax.amount && (
                                        <FormattedNumber
                                            value={paymentsByFiscalYearCreatedYearTax.amount.principal}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </>
    );
};
export default PaymentsTableComponent;

interface Props {
    councilId: number;
    startDate: Dayjs;
    endDate: Dayjs;
    process: ProcessType;
}
