import { Steps } from 'antd';
import { UploadFile } from 'antd/lib';
import { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { CensusDocument, CensusUrbanProperty } from '../../models/Entities';
import { TaxType } from '../../models/Types';
import alertService from '../../services/AlertService';
import styles from './CensusDocumentsImportPage.module.scss';
import CensusDocumentsResultComponent from './CensusDocumentsResultComponent/CensusDocumentsResultComponent';
import CensusDocumentsSummaryComponent from './CensusDocumentsSummaryComponent/CensusDocumentsSummaryComponent';
import CensusDocumentsUploadComponent from './CensusDocumentsUploadComponent/CensusDocumentsUploadComponent';

/**
 * Returns the census documents import page.
 * @returns the census documents import page.
 */
const CensusDocumentsImportPage: React.FC<Props> = (props) => {
    const { tax } = props;
    /*** HOOKS ***/

    const intl = useIntl();
    const { council } = useContext(CustomCouncilContext);
    const [step, setStep] = useState<number>(0);
    const [file, setFile] = useState<UploadFile>();
    const [censusUrbanProperties, setCensusUrbanProperties] = useState<CensusUrbanProperty[]>([]);
    const [censusDocument, setCensusDocument] = useState<CensusDocument>();

    /*** METHODS ***/

    const showSummary = async (file: UploadFile, censusUrbanProperties: CensusUrbanProperty[]) => {
        try {
            setFile(file);
            setCensusUrbanProperties(censusUrbanProperties);
            setStep(1);
        } catch (error) {
            alertService.displayError(error, intl);
        }
    };

    const showResult = async (censusDocument: CensusDocument) => {
        try {
            setCensusDocument(censusDocument);
            setStep(2);
        } catch (error) {
            alertService.displayError(error, intl);
        }
    };

    const back = () => {
        setStep(step - 1);
    };

    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="receivablesImport.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council.id}`, name: council?.name },
                { path: `/councils/${council.id}/census-urban-properties`, name: <FormattedMessage id="censusDocuments.title" /> },
                {
                    path: `/councils/${council?.id}/census-urban-properties/import`,
                    name: <FormattedMessage id="censusDocuments.import.title" />
                }
            ]}
            council={council}
        >
            <Steps current={step} className={styles.steps}>
                <Steps.Step title={<FormattedMessage id="censusDocuments.import.steps.1" />} />
                <Steps.Step title={<FormattedMessage id="censusDocuments.import.steps.2" />} />
                <Steps.Step title={<FormattedMessage id="censusDocuments.import.steps.3" />} />
            </Steps>

            <div hidden={step !== 0}>
                {' '}
                <CensusDocumentsUploadComponent council={council} tax={tax!} onLoad={showSummary} />{' '}
            </div>
            {step === 1 && (
                <CensusDocumentsSummaryComponent
                    council={council}
                    tax={tax!}
                    file={file}
                    censusUrbanProperties={censusUrbanProperties}
                    onImport={showResult}
                    onBack={back}
                />
            )}
            {step === 2 && censusDocument && <CensusDocumentsResultComponent council={council} censusDocument={censusDocument} />}
        </LayoutComponent>
    );
};
export default CensusDocumentsImportPage;

interface Props {
    tax: TaxType;
}
